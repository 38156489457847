export function guid() { 
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}
export function testOut(o, tag = null, printStackTrace = false) {
    // var stack = new Error().stack   wenn dieses nicht in console.log aufgerufen wird, sieht man nur die webpack-methoden
    // stack = stack.slice(6) // chop off "Error\n"
    // var caller = stack.split('\n')[2].trim(); // who called this method?
    
    var test = typeof o === 'string' ? o : JSON.stringify(o, undefined, 2)
    if (tag) {
        // console.log(">>>> " + caller + " " + tag + ">>>>>>>>>>>>>>>")
        console.log(">>>>" + tag + ">>>>>>>>>>>>>>>")
        console.log(test)
        if (printStackTrace) {
            // console.log("stack:")
            console.log(new Error().stack)
        }
        console.log("<<<<" + tag + "<<<<<<<<<<<<<<<")
    } else {
        // console.log(caller + ": " + test)
        console.log(test)
        if (printStackTrace) {
            console.log("stack:")
            console.log(new Error().stack)
        }
    }
}

export function pad(value, length) {
    var value_str = value.toString()
    if (value_str.length > length) return value_str.substring(0,length)
    return (value_str.length < length) ? pad(" "+value, length):value;
}
export function makeCopy(o) {
    return JSON.parse(JSON.stringify(o))
}
export function s2i(s) { // string to int
  if (!s) {
    return 0
  }
  if (!s.length) {
    return 0
  }
  var i = parseInt(s)
  if (isNaN(i)) {
    return 0
  }
  return i
}

export function objProps(obj) {
    var keys = Object.keys(obj) // TODO: check getOwnPropertyNames
      var retVal = []
      keys.forEach(function(prop) {
        retVal.push(prop)
      });
      return retVal
}

export function iso2Date(isoDate) {
    return new Date(isoDate.replace(/-/g,"/"));
}
export function toISOLocal(d) {
    d = d || new Date();
    var z = n => (n<10? '0':'')+n;
    var off = d.getTimezoneOffset();
    var sign = off < 0? '+' : '-';
    off = Math.abs(off);

    return d.getFullYear() + '-' + z(d.getMonth()+1) + '-' +
            z(d.getDate()) + 'T' + z(d.getHours()) + ':'  + z(d.getMinutes()) + 
            ':' + z(d.getSeconds()) + sign + z(off/60|0) + z(off%60); 
}

export default {
    guid:guid,
    testOut:testOut,
    pad:pad,
    makeCopy:makeCopy,
    objProps:objProps,
    s2i:s2i,
    iso2Date:iso2Date,
    toISOLocal:toISOLocal
}