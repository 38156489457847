<template>
  <b-container class="px-0">
      
          
  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
      <div  v-for="poll in polls" :key="poll._id">
          <schedule :forcePollId="poll._id" :markDates="getMarkDatesForPoll(poll._id)"></schedule>
      </div>
  </div>
  </b-container>
</template>

<script>
import Utils,{ makeCopy } from '../helper/Utils'
import DBAPI from '../db/dbapi'
import session from '../store/session'
import Schedule from '@/components/Schedule'

export default {
  name: 'ScheduleMulti',
  mounted() {
    // console.log("mounted Schedule.vue")

    // console.log("this.name", this.$options.name)
    
    this.processParams();
    this.processQuery();
    this.getData();
  },
  beforeDestroy() {

    
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processParams()
        this.processQuery()
      },
    },
  data () {
    return {
      
      polls: [],
    //   markDate:[],
      appState:'loading'
    }
  },
  computed: {
    
    
  },
  methods: {
    getData: function() {
      
      this.appState = 'loading'
      var self = this
      var userData = this.$$$userData()
      
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }

      var pollFilter = {active:true, club_nr: userData.club_nr};

      DBAPI.getPolls(pollFilter).then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error){
          console.error("error in getPolls", response.data.message)
          self.appState = 'error'

          //app.errorMessage = response.data.message;
        } else {
          self.setPolls(response.data);          
          self.appState = 'ready'
        }       
        }).catch( function(error) {
        console.error("error in getPolls (catch)", error)
        self.appState = 'error'
      });
 
    },
    getMarkDatesForPoll(pollId) {
        const doublettes = [];
        const map = {};
        for (var i = 0; i<this.polls.length;i++) {
            const poll = this.polls[i];
            poll.matches.forEach(m => {
                if (m.state >= 100) return;
                const isoDate = m.date.substring(0,10);
                if (!map[isoDate]) {
                    map[isoDate] = 1;
                } else {
                    doublettes.push(isoDate);
                }
            });
        }

        //retVal.push("2019-03-14");
        return doublettes;
    },
    setPolls(polls) {
        var storeData = this.$$$storeData();
        const self = this;
        
        if (storeData.lastPollId) {
            polls.forEach(p => {
                if (p._id === storeData.lastPollId) {
                    self.polls.push(p);
                }
            });
            // dann alle anderen, damit zuerst der kommt, der als letztes einzeln war
            polls.forEach(p => {
                if (p._id !== storeData.lastPollId) {
                    self.polls.push(p);
                }
            });

        } else {
            this.polls    = polls;
        }

    },
    processParams() {
      var myStore = this.$$$myStoreScope();

      if (this.$route.params) {
      } else {
      }
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
       
      }
    }

  },
  components: {
    Schedule
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>

.text-center {
    text-align: center;
}
.line-through {
  text-decoration: line-through;
}
</style>
