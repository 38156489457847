<template>
  <b-container class="px-0 mx-0">
       <b-row>
      <b-col class="d-none d-md-block col-md-2">
      </b-col>

          <b-col>
            <div class="col-md-auto text-center">
  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
    <b-list-group id="pollList">
      <b-list-group-item  v-for="poll in polls" :key="poll._id" :to="{path: '/poll/' + poll._id}">
        <div :class="poll.active ? '' : 'line-through'">{{ poll.title }}</div>
      </b-list-group-item>
      <b-list-group-item :to="{path: '/poll/club/' + $$$userData().club_nr }">
        <div>Alle {{$$$userData().team_full_name }}</div>
      </b-list-group-item>
    </b-list-group>
  </div>
              </div>
</b-col>
      <b-col class="d-none d-md-block col-md-2">
      </b-col>
         </b-row>

  </b-container>
</template>

<script>
import Utils,{ makeCopy } from '../helper/Utils'
import ModelUtils from '../helper/ModelUtils'
import DBAPI from '../db/dbapi'
import session from '../store/session'

export default {
  name: 'Polls',
  mounted() {
    // console.log("mounted Schedule.vue")

    // console.log("this.name", this.$options.name)
    
    this.processParams()
    this.processQuery()
    this.getData()
  },
  beforeDestroy() {

    
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processParams()
        this.processQuery()
      },
    },
  data () {
    return {
      
      polls: [],
      includeinactive:false,
      appState:'loading' //
    }
  },
  computed: {
    
    
  },
  methods: {
    getData: function() {
      
      this.appState = 'loading'
      var self = this
      var userData = this.$$$userData()

      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }

      var pollFilter = this.includeinactive ? { } : {active:true}

      if (userData.permission_group >= "500") {
        // admin sees all polls
      // } else if (userData.permission_group >= "300") {
      } else if (userData.permission_group >= "300"  || true) { // FIXME!! vorerst dürfen alle alles sehen
        // club admin sees all polls from club
        pollFilter.club_nr = userData.club_nr
      } else {
        // team admin and players see own
        pollFilter.player_id = userData.user_id
      }

      DBAPI.getPolls(pollFilter).then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error){
          console.error("error in getPolls", response.data.message)
          self.appState = 'error'

          //app.errorMessage = response.data.message;
        }	else {
          self.polls    = response.data
          self.appState = 'ready'
        }       
    }).catch( function(error) {
        console.error("error in getPolls (catch)", error)
        self.appState = 'error'
      });
    },
    processParams() {
      var myStore = this.$$$myStoreScope()
      if (this.$route.params) {
      } else {
      }
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        if (this.$route.query.includeinactive !== undefined) {
          if ('0' !== this.$route.query.includeinactive) {
            this.includeinactive = true
          } else {
            this.includeinactive = false
          }
        }
      }
    },
    hasPermissionForSchedule(readOrWrite, params /* id of other user to read or write */) {

      return true // FIXME!!! ist vorerst nicht eingeschränkt

      var userData = this.$$$userData()
      
      let forPlayerId = params.playerId
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      // may see schedules of own team
      if (userData.permission_group >= 500) { // admin
          return true
      } 
      
      // ---
      // standard permissions
      // ---
      // may see schedules of own club
      if (readOrWrite == "read" &&
          userData.club_nr == forClubNr /*&& userData.team_nr == params.team_nr*/) {
          return true
      } 
      
      // may edit own schedules
      if (readOrWrite == "write" &&
          userData.user_id == forPlayerId) {
          return true
      } 

      // team admin may edit schedules of own team members
      if (readOrWrite == "write" &&
          userData.club_nr == forClubNr && userData.team_nr == forTeamNr  && userData.permission_group >= 200 ) {
          return true
      } 

      return false
    },
    
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>

.text-center {
    text-align: center;
}
.line-through {
  text-decoration: line-through;
}
</style>
