const PlayerState = {
    NOT_SET: 'unset',
    CAN_PLAY: 'yes',
    CANNOT_PLAY: 'no',
    
    isSet:PlayerState_isSet,
    canPlay:PlayerState_canPlay,
    cannotPlay:PlayerState_cannotPlay,
    // isPlanned:PlayerState_isPlanned
}

function PlayerState_isSet(playerState) {
    if (!playerState || playerState == PlayerState.NOT_SET) {
        return false
    }
    return true
}

function PlayerState_canPlay(playerState) {
    if (playerState == PlayerState.CAN_PLAY) {
        return true
    }
    return false
}
function PlayerState_cannotPlay(playerState) {
    if (playerState == PlayerState.CANNOT_PLAY) {
        return true
    }
    return false
}
// function PlayerState_isPlanned(playerState) {
//     if (playerState == PlayerState.IS_PLANNED) {
//         return true
//     }
//     return false
// }
export default PlayerState;
