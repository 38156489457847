<template>
   <b-container class="mx-0 px-0">
     <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
      <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
      <div v-else-if="appState=='ready'"> 

      <b-row>
        <b-col>
          <div>
           <b-form-checkbox id="checkbox1"
                     v-model="showOpponentData"
              >  
              Gegner-Stats
            </b-form-checkbox>
            <b-form-checkbox id="checkbox2"
                     v-model="filterPresent"
              >  
              Filter Spieler
            </b-form-checkbox>
           <b-form-checkbox id="checkbox3"
                     v-model="scheduleForAllPlayers"
              >  
              Inkl. Ersatzspieler
            </b-form-checkbox>
           </div>
        </b-col>
        <b-col>
           
                        <b-button style="margin-top:5px" :to="{ name: 'TtrCalc', params:{ 
                          pollId: pollId,
                          matchId:matchId,
                        }
                      }"><icon name="calculator" scale="1.5"/> 
                        TTR Calculator</b-button>
     
        </b-col>
      </b-row>
      
      <b-row>
        <b-col class="col-auto">
          <b-table small bordered
            :items="playerScheduleRows"
            :fields="fieldsComputed"
            foot-clone
            >
            <template v-slot:[headname(matchColumumn.key)]="data">
              <!-- A custom formatted header cell for field 'name' -->
              <div>
                <p class="py-0 my-0" style="line-height: 1.1;">
                <icon v-if="data.field.away_yn" name="road"/> 
                <icon v-else name="home"/> 
                <br>
                {{ data.label }} <br>
                <small>{{ "" + data.field.matchDay + "."  + data.field.matchMonth + "."}} {{ "(" + data.field.matchWeekDay + ")"}}</small>
                </p>
              </div>
            </template>
            
            <template v-slot:[cellname(matchColumumn.key)]="row">
              <label class="pb-0 mb-0" v-if="canPlay(row.item[matchColumumn.key])"><icon class="pb-0 mb-0" v-bind:style="checkBoxStyle(row.item[matchColumumn.key])" name="check"/></label>
            </template> 
            <template v-slot:cell(playerCheckboxes)="row">
              <label class="p-0 m-0">
                <b-form-checkbox class="double-size px-2 m-0"
                  v-show="row.item.pos > 0"
                  :checked="canPlay(getStateForPlayer(row.item._id))"
                  :disabled="filterPresent"
                  @input="handleCheckboxUpdated(row, matchColumumn.key ,$event)"
                  >
                </b-form-checkbox>
                
              </label>
                      
            </template> 

            <template v-slot:cell(name)="row"> 
              <b-link target="_blank" :href="'https://www.mytischtennis.de/community/events?personId=' + row.item.person_id">{{ row.item.lname }}</b-link>
                      <sup v-if="showOpponentData && (row.item.ownBilanz || row.item.ownTtr)"><small><b-badge variant="primary">{{ s(row.item.ownBilanz) + ", " + s(row.item.ownTtr) }}</b-badge></small></sup>
            </template> 

            <template v-slot:cell(opponent)="row"> 
               <b-link target="_blank" :href="'https://www.mytischtennis.de/community/events?personId=' + row.item.oppPersonId">{{ row.item.oppLname }}</b-link>
                      <sup v-if="showOpponentData && (row.item.oppBilanz || row.item.oppTtr)"><small><b-badge variant="primary">{{ s(row.item.oppBilanz) + ", " + s(row.item.oppTtr) }}</b-badge></small></sup>
            </template> 
            <!-- footer  -->
            <template v-slot:foot()="row"><span></span>
            </template> 
           
              <template v-slot:[footname(matchColumumn.key)]="data">
                <div v-bind:class="{ 'text-danger' : (data.field.presentPlayersCount<6) }">
                  {{ data.field.presentPlayersCount }}
                </div>
            </template>
            </b-table>
          </b-col>
          <b-col class="border" style="min-width: 200px;">
            <b-row>
              <b-col>
            <div>
            <h5>Einzel</h5>
            <!-- https://jsfiddle.net/oscaralexander/9qkuavdz/ -->
            <transition-group name="scale" tag="ol">
              <li v-for="(player) in playerLineUp" :key="player.p_id">
                
                <b-row>
                <b-col cols="1">
                  <span class="mr-1 cursor-pointer" style="float:left;color:black;background-color: #eee;min-width:1.3em;text-align: center;"
                  @click="togglePlayerPlaysSingles(player)">{{ player.singlesPos ? player.singlesPos : '-' }}</span>
                </b-col>
                <b-col  style="float:left;">
                  <span>  {{ player.name }}</span>
                </b-col>
                <b-col>
                  <span class="ml-1 cursor-pointer" style="float: right;background-color:#dc3545;min-width:1.6em;text-align: center;"
                  @click="handleDoublePlayerClicked(player)">D{{ player.doublesPos==0?'-':player.doublesPos }}</span>
                </b-col>
                </b-row>
          
              </li>
            </transition-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
            <b-col>
            <!-- <div style="min-width: 250px;"> -->
            <div style="">
            <h5>Doppel</h5>
              <transition-group v-show="playerLineUp.length>3" name="scale" tag="ol">
                <li v-for="(double,index) in doublesLineUp" :key="double.id">
                  <!-- <span class="mr-1" style="float: left;background-color:red;width:2em;"> D{{ index+1 }}</span> &nbsp; -->
                  <span class="mr-1" style="float: left;background-color:#dc3545;width:1.6em;text-align: center;"> D{{ index+1 }}</span> 
                    <span styles="float: left;"> {{ doublesNames(double.double) }} <sub><small>{{doublesStrength(double.double)}}</small></sub></span>
                    
                </li>
              </transition-group>
              <b-alert class="mt-1 justify-content-md-center" :variant="doublesStatus.variant" :show="doublesStatus.show">{{doublesStatus.msg}}</b-alert>
            </div>
          </b-col>
         </b-row>
          </b-col>
         </b-row>
      <b-row class="mb-2">
        <b-col>
          
          <a v-if="hasPermissionForWhatsApp()" target="_blank" :href="whatsAppLink()">An WhatsApp</a>          
        </b-col>
    </b-row>
      </div>
     </b-container>
</template>

<script>

import Utils,{ makeCopy, s2i } from '../helper/Utils'
import DBAPI from '../db/dbapi'
import ModelUtils from '../helper/ModelUtils'
import PlayerState from '../helper/PlayerState'

export default {
  name: 'MatchPlan',
  mounted() {
    this.processParams()
    this.processQuery()
    this.getData()
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processParams()
        this.processQuery()
      },
    },
  data () {
    return {
      editModel:[], // same as "player" + holds position in team, doubles pos etc. (see getData)
      matchId:"",
      pollId:null,
      teams: [],
      matches: [],
      playerSchedules: [],
      showOpponentData: false,
      filterPresent: false,
      scheduleForAllPlayers: false,
      // resultsOwn: [],  // für die Anzeige (nicht mehr benötigt)
      resultsOpp: [],  // für die Anzeige 
      resultsOwn_db: {vr: [], rr: [], gesamt: []}, // aus DB, aufgeteilt in 'vr', 'rr', 'gesamt'
      resultsOpp_db: {vr: [], rr: [], gesamt: []}, // aus DB, aufgeteilt in 'vr', 'rr', 'gesamt'
      poll: { },
      appState:'loading'
    }
  },
  computed: {
    myClubNr() {return this.poll.club_nr},
    myTeamNr() {return this.poll.team_nr},
    groupId() {return this.poll.groupId},
  
    playerLineUp() {
      var lineUp = []
      for (var i=0;i<this.editModel.length;i++) {
        var e = this.editModel[i]
          if (e.player_state == 'yes') {
            lineUp.push(e)
          }
      }

      // line
      return lineUp // TODO: sort by pos not necessary?!
    },
    playerScheduleRows() {
      const self = this;
      var retVal = ModelUtils.playerScheduleRows(this.playerSchedules, undefined, this.poll.team_nr)

      if (this.filterPresent) {
        retVal = retVal.filter(player => this.canPlay(this.getStateForPlayer(player._id)));
      }

      if (!this.scheduleForAllPlayers) { // mit oder ohne Ersatzspieler?
        retVal = retVal.filter(player => this.poll.playerIds.includes( player._id));
      }

      if (this.showOpponentData) {
        var resultsOpp = this.resultsOpp
        
        if (this.resultsOwn_db['vr'].length) { // nur falls Daten gelesen wurden/Lesen fertig ist
          for (var i = 0; i < retVal.length;i++) {
            var row = retVal[i];
            var statsOwn = findResultsFor(row.person_id);
            if (!statsOwn) {
              // no stats found for player
                row.ownTtr  = '-'
                row.ownQttr = '-'
            } else {
                row.ownTtr  = statsOwn.ttr_data ? '' + statsOwn.ttr_data.ttr : '-';
                row.ownQttr = statsOwn.ttr_data ? '' + statsOwn.ttr_data.qttr : '-';
            }

            row.ownBilanz = '' + this.makeBilanzForPaarkreuz(0, statsOwn);
            
          }
        }
        if (resultsOpp && resultsOpp.length) { // nur falls Daten gelesen wurden/Lesen fertig ist
          for (var i = 0; i<resultsOpp.length;i++) {
            var statsOpp = resultsOpp[i];
            var row = { };
            if (i<retVal.length) {
              row = retVal[i];
            } else {
              retVal.push(row);
            }

            row.oppPersonId = statsOpp.person_id
            row.oppFname = statsOpp.fname
            row.oppLname = statsOpp.lname
            if (!statsOpp) {
              // no stats found for player
              row.oppTtr  = '-'
              row.oppQttr = '-'
            } else {
              row.oppTtr  = statsOpp.ttr_data ? '' + statsOpp.ttr_data.ttr : '-'
              row.oppQttr = statsOpp.ttr_data ? '' + statsOpp.ttr_data.qttr : '-'
            }

            row.oppBilanz = this.makeBilanzForPaarkreuz(0, statsOpp)
          }

        } 
      }
     
      
      return retVal;

      function findResultsFor(person_id) {
        var retVal = resultById(self.resultsOwn_db['gesamt'], person_id);
        if (retVal) return retVal;
        return resultById(self.resultsOwn_db['vr'], person_id);
      }
    }, 
    fieldsComputed() {
      
      if (!this.matches) {
        return []
      }
      var self = this
      
      var retVal = [
        {key: "posInThisTeam", label: "Pos"},
        {key: "name", label: "Name"}
        ]
      if (this.showOpponentData) {
        retVal.push(
          {key: "opponent", label:"Gegner"}
        )
      }
      retVal = retVal.concat([
        self.matchColumumn,
        {key: "playerCheckboxes", label: "Einsatz", class:"text-center"} 
        ])
      
      return retVal
    
    },
    matchColumumn() {
      var self = this
      var myClubNr = this.myClubNr
      var myTeamNr = this.myTeamNr
      var m = null;
      for(var i = 0;i<this.matches.length;i++) {
        var match = this.matches[i]
        if (match._id == this.matchId) {
          m = match
        }
      }
      if (!m) {
        return { }
      }
      var away_yn = m.away_club_nr == myClubNr && m.away_team_nr == myTeamNr
      var oppClubNr = (away_yn) ? m.home_club_nr : m.away_club_nr;
      var matchDate = new Date(m.date)

      var matchYear = matchDate.getFullYear() 
      var matchMonth = matchDate.getMonth() + 1
      var matchDay = matchDate.getDate() 
      var matchHours = matchDate.getHours() 
      var matchMinutes = matchDate.getMinutes() 
      let DAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var matchWeekDay = DAYS[matchDate.getDay()]
      var presentPlayersCount = self.playerStatsForMatch(m._id).present

      return {
          key:matchKey(m._id),
          label:self.teams[oppClubNr] ? self.teams[oppClubNr].short_name : oppClubNr, // falls Team nicht in DB
          away_yn:away_yn,
          matchDate:matchDate,
          matchYear:matchYear,
          matchMonth:matchMonth,
          matchDay:matchDay,
          matchHours:matchHours,
          matchMinutes:matchMinutes,
          matchWeekDay:matchWeekDay,
          presentPlayersCount:presentPlayersCount,
          class:"col-width text-center"
        }
    
    },
    /*
      [
        [player_a, player_b'], // Doppel 1
        [player_a, player_b'], // Doppel 2
        [player_a, player_b'], // Doppel 3
      ]

    */
    doublesLineUp() {
      var doubles = [{id:'notset0', double:[]}, {id:'notset1', double:[]}, {id:'notset2', double:[]}] // Doppel 1, 2 und 3
      for (var i = 0; i<this.editModel.length; i++) {
        var player = this.editModel[i]
        if (player.doublesPos > 0) {
          doubles[player.doublesPos - 1].double.push(player) 
        }
      }
      return doubles
    },
    doublesStatus() {
      var retVal = {variant: "info", msg: "", msg2: "", show:false}

      if (this.playerLineUp.length < 4) {
        return retVal  // zu wenige Spieler für das Einzel=>keine Info für Doppel anzeigen
      }

      // alle Doppel noch leer:
      if (!this.doublesLineUp[0].double.length && !this.doublesLineUp[1].double.length && !this.doublesLineUp[2].double.length) {
        retVal.show = true
        retVal.msg = "Füge Spieler für das Doppel hinzu (rote Buttons)"
        return retVal
      }

      // ein Doppel hat mehr als 2 Spieler
      for (var i = 0;i<this.doublesLineUp.length;i++) {
        var double = this.doublesLineUp[i].double
        if (double.length > 2) {
          return {variant: "danger", msg: "Doppel " + (i+1) + " hat " + double.length + " Spieler!", msg2:"",show:true}
        }
      }

      // noch nicht alle Doppel genau 2 Spieler
      if (this.playerLineUp.length >= 6 && 
        !((this.doublesLineUp[0].double.length == 2) && (this.doublesLineUp[1].double.length == 2) && (this.doublesLineUp[2].double.length == 2))) {
         return {variant: "warning", msg: "Doppel unvollständig",msg2:"", show:true}
      }

      // noch nicht alle Doppel genau 2 Spieler
      if (this.playerLineUp.length == 4 || this.playerLineUp.length == 5) {
        if (!((this.doublesLineUp[0].double.length == 2) && (this.doublesLineUp[1].double.length == 2)) ||
              this.doublesLineUp[2].double.length != 0) {
          return {variant: "warning", msg: "Bei " + this.playerLineUp.length + " Spielern: genau 2 Doppel!", msg2:"", show:true}
        } 
      }
      
      if ((this.doublesLineUp[0].double.length == 2) && (this.doublesLineUp[1].double.length == 2) && (this.doublesLineUp[2].double.length == 2)) {
        var strengthDouble2 = this.doublesLineUp[1].double[0].singlesPosForDoubles + this.doublesLineUp[1].double[1].singlesPosForDoubles
        var strengthDouble3 = this.doublesLineUp[2].double[0].singlesPosForDoubles + this.doublesLineUp[2].double[1].singlesPosForDoubles
        if (strengthDouble2 > strengthDouble3) {
          return {variant: "danger", msg: "Doppel 2 und 3 müssen getauscht werden!", msg2:"", show:true}        
        } else if (strengthDouble2 == strengthDouble3) {
          if (this.doublesLineUp[2].double[0].bestPlayerDouble_2_or_3 || this.doublesLineUp[2].double[1].bestPlayerDouble_2_or_3)
            return {variant: "danger", msg: "Doppel 2 und 3 müssen getauscht werden!", msg2:"", show:true}        
        }
      } 
    

      return {variant: "success", msg: "Doppel OK!",msg2:"", show:true}
      
      /*
primary, secondary, ,success, danger, warning, info, light, dark
      */
    },
    
  },
  methods: {
    headname(name) { return 'head(' + name + ')'},
    cellname(name) { return 'cell(' + name + ')'},
    footname(name) { return 'foot(' + name + ')'},
    getData: function() {
      this.appState = 'loading'
      var self = this
      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }
      var schedParams = {}
      if (this.pollId) {
        schedParams = {pollId: this.pollId}
      } else {
        // eine passende pollId muss zunächst anhand der userId (club_id, team_nr) ermittelt werden
        // das ist für type=u der Fall, siehe processParams
        schedParams = {playerId: userId}
      }
      
      schedParams.scheduleForAllPlayers = 1;//= this.scheduleForAllPlayers ? 1 : 0;

      DBAPI.getPlayerSchedules(schedParams)
      .then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error || response.data.errors){
          console.error("error in getPlayerSchedules", response.data)
          self.appState = 'error'

          //app.errorMessage = response.data.message;
        }	else {
          // console.log(response.data.poll)
          var teams   = {}
          response.data.teams.forEach(t => {teams[t.club_nr] = t})
          self.teams = teams
          
          self.matches = response.data.poll.matches || []
          self.poll = response.data.poll;

          // alle Spieler die Ersatz spielen dürfen:
          self.playerSchedules = response.data.allplayers.filter(p => Number(p.team_nr) >= Number(self.poll.team_nr));
          
          var poll = self.poll
          self.setPollId(poll._id)
          
          self.editModel = self.playerSchedules.map(p => {return {
            p_id : p._id,
            pos:p.pos,
            playsSingles:false,
            singlesPos:0, // 0 means no singles
            singlesPosForDoubles:0, // für check, ob Doppel korrekt aufgestellt
            bestPlayerDouble_2_or_3: false, // if true, player cannot play double 3
            doublesPos:0, // 0 means no doubles
            fname:p.fname,
            lname:p.lname,
            name:p.lname,
            player_state: PlayerState.CANNOT_PLAY
            }
          });

          // falls 6 oder weniger können: wähle diese automatisch aus

          for (var i = 0;i<self.playerSchedules.length;i++) {
            const sched_for_match = self.playerSchedules[i].schedule.find(s => s.match_id === self.matchId);
            if (sched_for_match.player_state === PlayerState.CAN_PLAY)
             self.setStateForPlayer(self.playerSchedules[i]._id,self.matchId, PlayerState.CAN_PLAY);
          }

          self.showOpponentData = true // TODO
          self.appState = 'ready'
          
         
          
        }       
    }).then(function(){
      self.getResultsOpp();
      self.getResultsOwn();
    })
    .catch( function(error) {
        console.error("error in getPlayerSchedules (catch)", error)
        self.appState = 'error'
      });
    },
    getResultsOpp() {
      var opponentTeamInfo = this.opponentTeamInfo();
      const filter = {club_nr:opponentTeamInfo.clubNr, group_nr:this.groupId};//,  round:this.poll.round};
      this.getResultsForTeamId( filter, 'opp' );
    },
    getResultsOwn() {
      const filter = {club_nr:this.myClubNr, group_nr:this.groupId};
      this.getResultsForTeamId(filter , 'own')
    },
    processParams() {
      
      if (this.$route.params) {
        this.setPollId(this.$route.params.pollId)
        this.matchId = this.$route.params.matchId
      } else {
        // poll zum Anzeigen nicht gefunden
        this.$router.push('/polls')
      }
    },
    setPollId(pollId) {
      this.pollId = pollId
      this.$$$storeSet("lastPollId", this.pollId)
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        
      }
    },
    getResultsForTeamId(filter, ownOrOpp) {
      
      var self = this
      // FIXME: auch von Spielern aus 2. Mannschaft lesen, die noch kein Spiel in der 1. gemacht haben
      
      DBAPI.getResults(filter).then(function(response) {
          if(response.data.error){
            //app.errorMessage = response.data.message;
            console.error("error calling getResultsForTeamId("+ clubNr + ")")
            console.error(response.data.message)
					}	else {
            self.setResults_db(ownOrOpp, response.data.records);
            if (ownOrOpp == 'opp') {
              self.makeOppTeam(); // Aufstellung für Opp (Aufstellung own ergibt sich aus poll bzw. schedule)
              // Daten beimischen (bspw. in rr die Bilanz aus gesamt)
              self.completeResultOppData(ownOrOpp);
            }
          }       
      });
    },
    setResults_db(ownOrOpp, records) {
      if (ownOrOpp !== 'own' && ownOrOpp !== 'opp') throw new Error("ownOrOpp must be 'own' or 'opp'")
      const results_db = ownOrOpp === 'own' ? this.resultsOwn_db : this.resultsOpp_db;
      
      const rounds = ['vr', 'rr', 'gesamt'];
      const self = this;

      rounds.forEach(function(r) {
          results_db[r] = records.filter(function(e) {
            return e.round===r;
          });

          results_db[r].sort(function(a,b) {
            if (a.team_nr > b.team_nr) return 1
            if (a.pos > b.pos) return 1
            if (a.pos < b.pos) return -1
              return 0;
          });

      });
    },
    makeOppTeam() {
      const self = this;
      const opponentTeamInfo = this.opponentTeamInfo();
      
      // vr muss immer vorhanden sein, rr ist erst ab Mitte/Ende Dezember verfügbar
      // (in gesamt fehlen leider die Spieler, die noch keinen Einsatz hatten)
      if (this.resultsOpp_db[this.poll.round] && this.resultsOpp_db[this.poll.round].length > 0) {
        // passende Daten für die round dieser Umfrage sind vorhanden
        this.resultsOpp_db[this.poll.round].forEach(function(r) {          
          if (r.team_nr >= opponentTeamInfo.teamNr) { // nur spielberechtigte, also nicht aus höheren Mannschaften
            self.resultsOpp.push(r);
          }
        });
      } else {
        if (this.resultsOpp_db['vr'].length === 0) console.log("warning", "resultsOpp_db['vr'] must not be empty")
        this.resultsOpp_db['vr'].forEach(function(r) {
          if (r.team_nr >= opponentTeamInfo.teamNr) {
            self.resultsOpp.push(r);
          }
        });
      }
    },
    completeResultOppData() {
      // resultById(results, player_id)
      
      const self = this;
      const results           = this.resultsOpp;
      const results_db_gesamt = this.resultsOpp_db['gesamt'];
      const results_db_vr     = this.resultsOpp_db['vr'];

      results.forEach(function(r){
        
        var gesamt = resultById(results_db_gesamt, r.person_id);
        if (!gesamt) { // kommt vor, wenn ein Spieler noch nicht gespielt hat. Damit ttr_data gefüllt werden kann
          gesamt = resultById(results_db_vr, r.person_id);
        }
        // console.log('gesamt', r._id);
        r.results  = makeCopy(gesamt.results);
        r.ttr_data = makeCopy(gesamt.ttr_data);
      });

    },
    findPlayerData(playerId) {
      return ModelUtils.findPlayerData(this.playerSchedules, playerId)
    },
    opponentTeamInfo() {
      var m = this.findMatch()
      if (!m) {
        return null
      }
      var myClubNr = this.myClubNr
      var away_yn = (m.away_club_nr == myClubNr)
      var oppClubNr = away_yn ? m.home_club_nr : m.away_club_nr;
      var oppTeamNr = away_yn ? m.home_team_nr : m.away_team_nr;

      return {clubNr:oppClubNr,teamNr:oppTeamNr}
      
    },
    findMatch() {
      if (!this.matches) {
        return null
      }
      for(var i = 0;i<this.matches.length;i++) {
        var match = this.matches[i]
        if (match._id == this.matchId) {
          return match
        }
      }      
      return null
    },
    playerStatsForMatch(matchId) {
      var playerStats = {present:0, total:0}
      if (!this.playerSchedules) {
        return playerStats
      }
      this.playerSchedules.forEach(sched => {
        sched.schedule.forEach(m => {
          if(m.match_id == matchId) {
            playerStats.total++
            if (PlayerState.canPlay(m.player_state)) {
              playerStats.present++
            }
          }
        })
      });
      return playerStats
    },
    handleCheckboxUpdated(row, matchId, event) {
      var item = row.item
      var checked = event
      var newState = checked ? 'yes' : 'no'
      if (item._id) { // item._id is undefined for last few rows when more opponents than own players
        this.setStateForPlayer(item._id, matchId, newState)
      }
    },
    whatsAppLink() {
      if (this.playerLineUp.length == 0) return "";
      var whatsAppText = this.whatsAppText()
      var link = "https://api.whatsapp.com/send?text=" + whatsAppText
      return link
//      return "https://api.whatsapp.com/send?text=I'm%20inquiring%20about%20the%20apartment%20listing"
    },
    whatsAppText() {
      var watext = "Aufstellung gegen "
      // {{ "" + data.field.matchDay + "."  + data.field.matchMonth + "."}}</small><br>
      //           <small>{{ "(" + data.field.matchWeekDay + ")
      var mc = this.matchColumumn
      var opponentTeamInfo = this.opponentTeamInfo()
      var opponentClubNr = opponentTeamInfo.clubNr
      var oppFullName = this.teams[opponentClubNr] ? this.teams[opponentClubNr].full_name : opponentClubNr
      if (opponentTeamInfo.teamNr != 1) {
        oppFullName = oppFullName + " " + opponentTeamInfo.teamNr
      }
      watext += `${oppFullName} am ${mc.matchDay}.${mc.matchMonth}. -->\n`


      var playerLineUp = this.playerLineUp
      for (var i = 0; i < playerLineUp.length;i++) {
        watext += "" + playerLineUp[i].singlesPos + " " + playerLineUp[i].name + "\n"
      }
      return encodeURI(watext)
    },
    handleDoublePlayerClicked(player) {
      player.doublesPos = (player.doublesPos + 1) % 4 
      this.updateSinglesPositions()
    },
    // only updates the local editModel
    setStateForPlayer(playerId, matchId, state) {
      if (!this.editModel) {
        throw new Error("editModel not set")
      }

      var stateObj = this.getStateForPlayer(playerId)
      // this.editModel[playerId] = state

      // stateObj for the player in this.editModel
      this.$set(stateObj, 'player_state', state)
      if (state == 'no') { // kann auch kein Doppel spielen
        this.$set(stateObj, 'doublesPos', 0)  
        this.$set(stateObj, 'singlesPos', 0)  
        this.$set(stateObj, 'singlesPosForDoubles', 0)  
        this.$set(stateObj, 'playsSingles', false)  
      }
     if (state == 'yes') { 
        // this.$set(stateObj, 'doublesPos', 0)  
        this.$set(stateObj, 'singlesPos', 0)  
        this.$set(stateObj, 'singlesPosForDoubles', 0)  
        this.$set(stateObj, 'playsSingles', true)  
      }
      this.updateSinglesPositions()
    }, 
    // based on editModel
    getStateForPlayer(playerId) {
      if (!this.editModel) {
        throw new Error("getStateForPlayer: editModel not set")
      }
      return this.editModel.find(em => em.p_id == playerId)
    },
    doublesNames(double) {
      if (double.length == 0) {
        return "-"
      }
      if (double.length == 1) {
        return double[0].name + " / -"
      }
      if (double.length == 2) {
        var double_0 = double[0].name + (double[0].bestPlayerDouble_2_or_3 ? "*" : "")
        var double_1 = double[1].name + (double[1].bestPlayerDouble_2_or_3 ? "*" : "")
        return double_0 + " / " + double_1
      }
      return "" + double.length + " Spieler!"
    },
   doublesStrength(double) {
      // sigma=\u03A3
      if (double.length == 2) {
        return  double[0].singlesPosForDoubles + "+" + double[1].singlesPosForDoubles + "=" +
                (double[0].singlesPosForDoubles + double[1].singlesPosForDoubles)
      }
      return ""
    },
    
    togglePlayerPlaysSingles(player) {
      if (player.playsSingles) {
        player.playsSingles = false
      } else {
        player.playsSingles = true
      }
      this.updateSinglesPositions()
    },
    updateSinglesPositions() {
      var singlesPosCnt = 0
      var singlesPosForDoublesCnt = 0
      
      var isSetBestPlayerDouble_2_or_3 = false
      for (var i = 0; i < this.editModel.length; i++) {
        var p = this.editModel[i]
        if (p.playsSingles) {
          singlesPosCnt++
          p.singlesPos = singlesPosCnt
        } else {
          p.singlesPos = 0
        }
        p.bestPlayerDouble_2_or_3 = false
        // für die Doppelaufstellung müssen die Positionen *ohne*
        // Berücksichtigung der Spieler, die kein Doppel spielen,
        // ermittelt werden
        if (p.doublesPos == 0) { // spielt kein Doppel
          p.singlesPosForDoubles = 0
        } else {
          singlesPosForDoublesCnt++
          p.singlesPosForDoubles = singlesPosForDoublesCnt
          if (!isSetBestPlayerDouble_2_or_3 && (p.doublesPos == 2 || p.doublesPos == 3)) {
            p.bestPlayerDouble_2_or_3 = true // if sum of doubles 2 and 3 are equal, this player must play double 2
            isSetBestPlayerDouble_2_or_3 = true
          }
        }
      }
    },
    goBack: function() {
      this.$router.go(-1);
    },
    canPlay(playerState) {
      if (!playerState) return false
      return PlayerState.canPlay(playerState.player_state)
    },
    cannotPlay(playerState) {
      if (!playerState) return true
      return PlayerState.cannotPlay(playerState.player_state)
    },
    checkBoxStyle(playerState) {
      if (!playerState || playerState.captain_state == 'unset') {
        return {color: "green"};
      }
      let captain_state = playerState.captain_state
      if (captain_state == 'yes') {
        return {color: "red"}
      }
      return {color: "green"}
    },
    hasPermissionForWhatsApp(readOrWrite, params /* id of other user to read or write */) {
      var userData = this.$$$userData()
      
      let forClubNr = this.myClubNr//this.poll.club_id TODO
      let forTeamNr = this.myTeamNr //this.poll.team_nr TODO
      
      if (userData.permission_group >= 500) { // admin
          return true
      } 
      
      
      // club admin
      if (//readOrWrite == "write" &&
          userData.club_nr == forClubNr && userData.permission_group >= 200 ) {
          return true
      } 
      // team admin
      if (//readOrWrite == "write" &&
          userData.club_nr == forClubNr && userData.team_nr == forTeamNr  && userData.permission_group >= 200 ) {
          return true
      } 
    },
    makeBilanzForPaarkreuz(_1_2_or_3, result) {
      // _1_2_or_3 = Paarkreuz
      if (!result || !result.results || result.results.length === 0) return ""

      var retVal = ""
      if (_1_2_or_3 == 0 ) { // sum
        var won_sum = result.results.reduce((acc, curr) => acc + (curr ? curr.w : 0),0)
        var lost_sum = result.results.reduce((acc, curr) => acc + (curr ? curr.l : 0),0)
        
        if (won_sum + lost_sum > 0) {
            retVal = "" + won_sum + ":" + lost_sum
        }

      } else {
        
        var _1_3_or_5 = _1_2_or_3 * 2 - 1 // pos of the better player in the paarkreuz
        var won  = result.results[_1_3_or_5 - 1].w + result.results[_1_3_or_5].w
        var lost = result.results[_1_3_or_5 - 1].l + result.results[_1_3_or_5].l
        
        if (won + lost > 0) {
            retVal = "" + won + ":" + lost
        }        
      }
         
      return retVal
    },
    s(str) {
      if (!str || !str.length) {
        return "-"
      }
      return str
    }
  }
}

function matchKey(matchId) {
  return "" + matchId
}
function resultById(results, personId) {

  if (!results) {
      return null
  }

  var retVal = null
  results.forEach(r => {
      if (r.person_id == personId) {
        retVal = r
      }
  });

  return retVal
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cursor-pointer {
  cursor:default;/* pointer;*/
}

.cursor-pointer:hover {
    cursor: pointer;
}

.double-size
{
  /* Double-sized Checkboxes */
  transform: scale(1.3);
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  /* padding: 10px; */
}
label{  
    /* width:200px;        */

    /* position:relative; */
    /* left: -20px; */
    text-align: center;
    display:inline-block;    
    vertical-align:middle; 
}
.col-width {
   width: 3.5em;
}
.text-center {
    text-align: center;
}


ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
ol li {
  background-color: #868e96;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 2px;
  padding: 6px 6px;
}
/* ol li span {
  color: #999;
  float: right;
} */
ol li.scale-enter-active, ol li.scale-move, ol li.scale-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
ol li.scale-enter {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
ol li.scale-leave-active {
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.list-group .list-group-item.scale-enter-active, .list-group .list-group-item.scale-move, .list-group .list-group-item.scale-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.list-group .list-group-item.scale-enter {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.list-group .list-group-item.scale-leave-active {
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
</style>
