<template>
  <!-- <div id="app" class="container px-0"> -->
  <b-container id="app" class="px-0">
    <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>


    <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
    <div v-else-if="appState=='error'">error</div> 
    <div v-else-if="appState=='ready'">

      <div v-if="$$$isTest()" style="background-color:red;">
      <h4>TEST version={{ VUE_APP_VERSION }}, buildtime={{ VUE_APP_BUILDTIME }}</h4>
      </div>
      
      
      <!-- <div class="row justify-content-md-center" style="background-color:green;"> -->
        <b-row>
          <!-- <b-col></b-col> -->
          <b-col>
            <!-- navbar-expand-sm: workaround, bug in bootstrap?! -->
            <b-navbar toggleable class="my-0 py-0 navbar-expand-sm" type="dark" variant="primary">
                  <!-- <b-nav-item to="/poll/last">Plan</b-nav-item> -->
                 <b-navbar-nav class="my-0 py-0" >
                  <b-nav-item class="my-0 py-0" :to="'/poll/'+lastPollIdForLink">Plan</b-nav-item>
                  <b-nav-item class="my-0 py-0" to="/polls">Übersicht</b-nav-item>
                </b-navbar-nav>
            <b-navbar-toggle class="my-0 py-0" target="nav_dropdown_collapse"></b-navbar-toggle>

              <b-collapse class="my-0 py-0" is-nav id="nav_dropdown_collapse">
                <b-navbar-nav class="my-0 py-0">
                
                  <b-nav-item-dropdown text="Bilanzen">
                    <b-dropdown-item :to="'/records'">Alle</b-dropdown-item>
                    <b-dropdown-item :to="'/records?club_nr=' + $$$userData().club_nr">{{$$$userData().team_short_name}}</b-dropdown-item>
                  </b-nav-item-dropdown>
                  <b-nav-item-dropdown text="Nutzer">
                    <b-dropdown-item :to="'/user/'+$$$userData().user_id">{{ $$$userData().fname }}</b-dropdown-item>
                    <b-dropdown-item v-if="isAdmin()" to="/?userId=">abmelden</b-dropdown-item>
                  </b-nav-item-dropdown>
                  <b-nav-item-dropdown v-if="isAdmin()" text="Admin">
                    <b-dropdown-item :to="'/admin/'+$$$userData().club_nr">Spielerverwaltung</b-dropdown-item>
                    <!-- <b-dropdown-item to="/editpoll/last">Poll bearbeiten</b-dropdown-item> -->
                    <b-dropdown-item :to="'/editpoll/'+lastPollIdForLink">Poll bearbeiten</b-dropdown-item>
                    <b-dropdown-item to="/importmatches">Neue Saison</b-dropdown-item>
                    <b-dropdown-item to="/dbadmin">DB Mgt</b-dropdown-item>
                  </b-nav-item-dropdown>
                  
                </b-navbar-nav>
            </b-collapse>
           
          </b-navbar>
          <transition name="fade">
              <!-- <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/>
              <div v-else-if="appState=='error'">Fehler beim Lesen der Daten: Internetverbindung checken und noch einmal probieren.</div> 
              <router-view v-else-if="appState=='ready'"/> -->
              <router-view/>
          </transition>
          <div v-if="false" style="color:lightgrey;border-style: solid none none none;border-width: 1px;"><small>ttoodle 1.0.2</small></div>
        </b-col> 
        <!-- <b-col></b-col> -->

      </b-row>
      </div>
      
  <!-- </div> -->
  </b-container>
</template>

<script>
import session from './store/session'
import cookie from './store/cookie'
import MessageAlertMixin from './mixins/MessageAlertMixin'

export default {
  name: 'App',
  mixins:[MessageAlertMixin],
  beforeDestroy() {
  },
  watch: {
    '$route': function(newPath, oldPath) {
      // console.log("watch App.vue", this.$route.fullPath)
      this.hideAlert()
      this.processQuery()
      this.getData() // signInUserId might have changed
      // this.$$$storeSet("lastVisitedUrl", this.$route.fullPath)
    }
  },
  mounted() {
    // console.log("mounted App.vue", this.$route.fullPath)
    // this.$$$storeSet("lastVisitedUrl", this.$route.fullPath)
    this.processQuery()
    this.getData()
  },
  data() {
    return{
    //  signInUserId:null,
     appState:'loading',
     VUE_APP_VERSION: process.env.VUE_APP_VERSION,
     VUE_APP_BUILDTIME: process.env.VUE_APP_BUILDTIME,
    }
  },
  computed: {
    lastPollIdForLink() {
      return 'last'
      // warum bekommt man lastPollId nicht reactive?!?!?!
      // return this.$$$storeData().lastPollId ? this.$$$storeData().lastPollId : 'last'
    }
    
  },
  methods: {
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))


        
    },
    getData() {
      this.appState = 'loading'
      var self = this
      let signInUserId = this.$route.query.userId
      // console.log("query.userId", self.$route.query.userId)
      if (true) { // hier evt. verfeinern?
      
        Promise.all([session.updateUserData(signInUserId, self.$$$store)])
        .then(([ud]) => {
          if (ud.userId === "") {
            // console.error("App.vue: nicht angemeldet!")
            self.showAlert("nicht angemeldet!",{dismissible:false})

            self.appState = 'error'
          } else {
            self.didGetData()
            self.appState = 'ready'
          }
        })
        .catch( function(error) {
            console.error("error in App.getData (catch)", error)
            self.showAlert("Anmeldung fehlgeschlagen: " + error)
            self.appState = 'error'
          });
      }
    },
    // user related parms make only sense after user data has been read
    didGetData() {
      let query = this.$route.query

      if (query.dgpg || query.dgpg === "" /* alias fo downgradePermissonGroup */) {
        this.$$$storeSet("downgradePermissonGroup", query.dgpg)
      }
      if (query.downgradePermissonGroup || query.downgradePermissonGroup === "") {
        this.$$$storeSet("downgradePermissonGroup", query.downgradePermissonGroup)
      }
      if (query.noEdit || query.noEdit === "") {
        var noEdit = query.noEdit
        if (noEdit === "0") { // "0" should reset noEdit
          noEdit = ""
        }
        this.$$$storeSet("noEdit", noEdit)
      }

    },
    isAdmin() {
      var userData = this.$$$userData()
      
      if (userData.permission_group >= 500) { // admin
          return true
      } 
      return false
    },
    testOut() {
      console.log("store:", JSON.stringify(this.$$$storeData(), undefined, 2))
      console.log("ud:", JSON.stringify(this.$$$userData(), undefined, 2))
      console.log("cookies:", JSON.stringify(this.$$$store.cookies(), undefined, 2))
    }
  }
}
</script>
<style lang="scss">
// #app {
//   font-family: 'Avenir', Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
// #nav {
//   padding: 30px;
//   a {
//     font-weight: bold;
//     color: #2c3e50;
//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top:60px; */
}
.preserve-lb {
  white-space: pre-wrap; 
  word-wrap: break-word;
  font-family: inherit;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
