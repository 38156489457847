/*
    
    permission_group
    ----------------
    500 = admin
    300 = club admin
    200 = team admin

    permissions (String)
    ---------------------
    "1,2,3" = permissions 1, 2 and 3
    1001 = can omit info mail (see dbupdateapi.php and Schedule.vue)
    1002 = can delete player state for a match (see Schedule.vue)
    1003 = can hide players in schedule (see Schedule.vue)
    1004 = can edit captain_state in schedule (see Schedule.vue)
    


*/

function hasPermission(userData, permission) {
    if (!userData) {
        return false
    }
    let p = "" + permission
    let permissions = userData.permissions.split(",") 
      
    if (permissions.includes(p)) {
        return true
    }
    return false
}

export default {
    hasPermission:hasPermission,
}