import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// only import the icons you use to reduce bundle size
import 'vue-awesome/icons/eye-slash'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/trash'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/road'
import 'vue-awesome/icons/spinner'
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/thumbs-up'
import 'vue-awesome/icons/thumbs-down'
import 'vue-awesome/icons/user-slash'
import 'vue-awesome/icons/minus'
import 'vue-awesome/icons/ban'
import 'vue-awesome/icons/calculator'

// or import all icons if you don't care about bundle size
// import 'vue-awesome/icons'

/* Register component with one of 2 methods */
import Icon from 'vue-awesome/components/Icon.vue';
// globally (in your main .js file)
Vue.component('icon', Icon);


Vue.use(BootstrapVue);

Vue.config.productionTip = false

// store
import Store from './store/Store'
Store.updateFromCookies()

Store.install = function() {
  Object.defineProperty(Vue.prototype, '$$$store', {
    get () { return Store }
  })
}
Vue.use(Store);

// $$$ is our own prefix 
Vue.prototype.$$$isTest = function() {
  if (document.URL.includes('http://localhost') || document.URL.includes('127.0.0.1') || document.URL.includes('192.168.178.') || document.URL.includes('/t/')) return true
  return false
}
Vue.prototype.$$$isLocal = function() {
  if (document.URL.includes('http://localhost') || document.URL.includes('127.0.0.1') || document.URL.includes('192.168.178.')) return true
  return false
}
Vue.prototype.$$$myStoreScope = function() {
  return Store.getScope(this.$options.name) // TODO: tuts nicht wirklich, wenn mehrere instances einer view gleichzeitig verwendet werden, weil diese dann den gleichen scope haben.
}

Vue.prototype.$$$userData = function() {
  return Store.getUserData()
}
Vue.prototype.$$$storeData = function() {
  return Store.getStoreData()
}
Vue.prototype.$$$storeGet = function(field) {
  return Store.get(field)
}
Vue.prototype.$$$storeSet = function(field, value, scope) {
  Store.set(field, value, scope)
}
Vue.prototype.$$$myStoreSet = function(field, value) {
  Store.set(field, value, this.$options.name)
}
Vue.prototype.$$$myStoreGet = function(field) {
  return Store.get(field, this.$options.name)
}
