import Vue from 'vue'
import Router from 'vue-router'
import Polls from '@/components/Polls'
import Schedule from '@/components/Schedule'
import ScheduleMulti from '@/components/ScheduleMulti'
import MatchPlan from '@/components/MatchPlan'
import PlayerRecords from '@/components/PlayerRecords'
import TtrCalc from '@/components/TtrCalc'

import Store from '@/store/Store'

Vue.use(Router)


export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/land/:type',
      name: 'LandingPage',
      component: Schedule,
      
    },
    {
      path: '/polls',
      name: 'Polls',
      component: Polls
    },
    // {
    //   path: '/', redirect: to => {
    //     if (Store.lastPollId && Store.lastPollId.length) {
    //       return {path: '/poll/'+ Store.lastPollId}
    //     }
    //     return {path: '/polls'}
    //   }
    // },
    {
      path: '/poll/last', redirect: to => {
          let lastPollId = Store.get('lastPollId')
          if (lastPollId && lastPollId.length) {
            return {path: '/poll/'+ lastPollId}
          }
          return {path: '/polls'}
        }
    },
    {
      path: '/poll/:pollId',
      name: 'Poll',
      component: Schedule
    },
    {
      path: '/poll/club/:clubId',
      name: 'ScheduleMulti',
      component: ScheduleMulti
    },
    {
      path: '/poll/:pollId/matchplan/:matchId',
      name: 'MatchPlan',
      component: MatchPlan
    },
    {
      path: '/poll/:pollId/ttrcalc/:matchId',
      name: 'TtrCalc',
      component: TtrCalc
    },
    {
      path: '/editpoll/last', redirect: to => {
        let lastPollId = Store.get('lastPollId')
        if (lastPollId && lastPollId.length) {
          return {path: '/editpoll/'+ lastPollId}
        }

        return {path: '/polls'}
      }
    },
    {
      path: '/editpoll/:pollId',
      name: 'EditPoll',
      // component: EditPoll
      component: () => import(/* webpackChunkName: "editpoll" */ '@/components/EditPoll')


    },
    {
      path: '/user/:userId',
      name: 'UserView',
      component: () => import(/* webpackChunkName: "userview" */ '@/components/UserView')
    },
    {
      path: '/admin/:clubNr', 
      name: 'Admin',
      // component: Admin
      component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin')
    },
    {
      path: '/dbadmin', 
      name: 'DBAdmin',
      // component: DBAdmin
      component: () => import(/* webpackChunkName: "dbadmin" */ '@/components/DBAdmin')
    },
    {
      path: '/records',
      name: 'PlayerRecords',
      component: PlayerRecords
    },
    {
      path: '/adjourn',
      name: 'AdjournMatch',
      component: () => import(/* webpackChunkName: "adjournmatch" */ '@/components/AdjournMatch')
    },
    {
      path: '/importmatches',
      name: 'ImportMatches',
      component: () => import(/* webpackChunkName: "importmatches" */ '@/components/ImportMatches')
    }
  ]
})
