
export function findPlayerData(playerSchedules, playerId) {
    if (!playerSchedules) {
        return null;
    }

    var retVal = null
    playerSchedules.forEach(ps => {
        if (ps._id == playerId) {
        retVal = ps;
        }
    });

    return retVal;
}
export function playerScheduleRows(playerSchedules, editPlayerId, forTeamNr, hiddenPlayerIds, ignoreInactivePlayers=false) {
    
    // console.log("forTeamNr", forTeamNr)
    if (!forTeamNr) { // TODO: was sollte das hier?!
        forTeamNr = 1;
    }
    
    var retVal = []; // array of players, sorted bei pos {id:1, pos: 1, name: "Lothar", game1: {}, }
    
    if (!playerSchedules) {
      return retVal 
    }
    
    for (var i = 0; i < playerSchedules.length; i++) {
        var playerSchedule = playerSchedules[i]
        if (hiddenPlayerIds && hiddenPlayerIds.length) {
            if (hiddenPlayerIds.includes(playerSchedule._id)) {
                continue
            }
        }
        // console.log(editPlayerId, playerSchedule._id, editPlayerId !== playerSchedule._id)
        if (ignoreInactivePlayers && editPlayerId !== playerSchedule._id && playerSchedule.is_active != 1) {
            continue
        }
        var playerRow = { }
        
        playerRow._id = playerSchedule._id
        playerRow.user_id = playerSchedule.user_id
        playerRow.person_id = playerSchedule.person_id
        playerRow.club_nr = playerSchedule.club_nr
        playerRow.team_nr = playerSchedule.team_nr
        playerRow.pos = playerSchedule.pos
        playerRow.fname = playerSchedule.fname
        playerRow.lname = playerSchedule.lname
        playerRow.is_active = playerSchedule.is_active
        playerRow.teamWithPos = "" + playerSchedule.team_nr + "/" + playerSchedule.pos
        playerRow.teamWithPosPreferred = "" + playerSchedule.team_nr_preferred + "/" + playerSchedule.pos_preferred
        
        playerRow._cellVariants = { }
        if (forTeamNr == playerSchedule.team_nr_preferred) {
            playerRow.posInThisTeam = playerSchedule.pos_preferred
        } else {
            playerRow.posInThisTeam = playerRow.teamWithPosPreferred
            playerRow._cellVariants["fname"] = "secondary"
        }
        // playerRow.posInThisTeamWithInitials = '' + playerSchedule.pos_preferred + playerSchedule.fname.charAt(0) + playerSchedule.lname.charAt(0) // with pos the text doesn`t fit in the cell
        playerRow.posInThisTeamWithInitials = playerSchedule.fname.charAt(0) + playerSchedule.lname.charAt(0)
        playerRow.editable = false
            
        if (editPlayerId) {
            if (playerRow._id == editPlayerId) {
                playerRow.editable = true
            }
        }
        for (var m = 0; m < playerSchedule.schedule.length; m++) {
            var matchSchedule = playerSchedule.schedule[m]
            var mk = matchSchedule.match_id
            playerRow[mk] = {player_state: matchSchedule.player_state, captain_state: matchSchedule.captain_state}
            
            if ( matchSchedule.player_state == "yes") {
              playerRow._cellVariants[mk] = "success"
              if (!playerRow.is_active) {
                playerRow._cellVariants[mk] = "warning"
            }
            } else if  ( matchSchedule.player_state == "no") {
              playerRow._cellVariants[mk] = "danger"
            } else {
              playerRow._cellVariants[mk] = "secondary"
            }
            
        }
        
        retVal.push(playerRow)
    }    
    return retVal
}
export default {
    findPlayerData:findPlayerData,
    playerScheduleRows:playerScheduleRows,
}