import cookie from './cookie'

let UserGuest = {
    userId: "",
    userData: { "_id": "", "user_id":"", "team_id": "0", "pos": "0", "fname": "-", "lname": "-", "email": "", "send_update_mail": false, "is_active": false, "person_id": "", "team_full_name": "", "team_short_name": "", "ttr": 0, qttr: 0},
    lastPollId: "",
}
var storeData = 
 {
    userId: "",
    userData: JSON.parse(JSON.stringify(UserGuest)), //{ "_id": "", "user_id":"", "team_id": "0", "pos": "0", "fname": "-", "lname": "-", "email": "", "send_update_mail": false, "is_active": false, "person_id": "" },
    lastPollId: "",
    downgradePermissonGroup: ""    
}


const cookieFields = [
    "userId",
    "lastPollId",
    "downgradePermissonGroup",
    "noEdit"
]


// dient nur dazu, functions / statische methoden zu bündeln
class Store {
    static get(field, scope) {
        if (scope) {
            return storeData[scope][field]
        }
        return storeData[field]
    }

    // scope = "Schedule" for example
    
    static set(field, value, scope) {
        if (scope) {
            if (!storeData[scope]) {
                storeData[scope] = { }
            }
            storeData[scope][field] = value
        } else {
            if (cookieFields.includes(field)) {
                cookie.setItem(field, value)
            }
            storeData[field] = value
        
        }
    }
    static getScope(scope) {
        if (!storeData[scope]) {
            storeData[scope] = { }
        }
        return storeData[scope]
    }
    static getUserData() {
        return storeData.userData
    }
    static getStoreData() {
        // updateStoreFromCookies()
        return storeData
    }
    static setUserData(userData) {
        storeData.userId = userData.user_id;
        cookie.setItem("userId", storeData.userId);
        storeData.userData = userData;
    }
    static signOut() {
        return this.reset().userData
    }
    static reset() {
        // console.log("------RESET-------")
        // storeData = JSON.parse(JSON.stringify(UserGuest))
        storeData.userId = ""
        storeData.userData = JSON.parse(JSON.stringify(UserGuest))
        storeData.lastPollId = ""
        storeData.downgradePermissonGroup = ""
        storeData.noEdit = ""
        cookie.setItem("userId", "")
        cookie.setItem("lastPollId", "")
        cookie.setItem("downgradePermissonGroup", "")
        cookie.setItem("noEdit", "")
        
        cookie.removeItem("userId")
        cookie.removeItem("lastPollId")
        cookie.removeItem("downgradePermissonGroup")
        cookie.removeItem("noEdit")
        
        return this.getStoreData()
    }
    static updateFromCookies() {
        let c_userId = cookie.getItem("userId")
        let c_lastPollId = cookie.getItem("lastPollId")
        let c_downgradePermissonGroup = cookie.getItem("downgradePermissonGroup")
        let c_noEdit = cookie.getItem("noEdit")
        
        // if (!storeData.userId && c_userId) {
            storeData.userId = c_userId || ""
        // }
        // if (!storeData.lastPollId && c_lastPollId) {
            storeData.lastPollId = c_lastPollId || ""
        // }

        storeData.downgradePermissonGroup = c_downgradePermissonGroup || ""
        storeData.noEdit = c_noEdit || ""
        
    }
    static landingPageForUser(userId) {
        if (!userId) {
            if (storeData.userId) {
                userId = storeData.userId
            } else {
                return '/polls'
            }
        }
        return `/land/u?userId=${userId}`
    }
    static getPermissionGroup() {
        var downgradePermissonGroup = Number.MAX_SAFE_INTEGER;
        
        if (storeData.downgradePermissonGroup && storeData.downgradePermissonGroup !== "") {
            downgradePermissonGroup = parseInt(storeData.downgradePermissonGroup)
            if (isNaN(downgradePermissonGroup)) {
                downgradePermissonGroup = 0
            }
        }
        return Math.min(storeData.userData.permission_group,downgradePermissonGroup) || 0
    }
    static cookies() {
        // TODO: use cookieFields
        return {
            userId:cookie.getItem("userId"),
            lastPollId: cookie.getItem("lastPollId"),
            downgradePermissonGroup: cookie.getItem("downgradePermissonGroup"),
            noEdit: cookie.getItem("noEdit"),
            
        }
    }
}


export default Store
