<template>
<b-container class="py-0 px-0">
  
    <b-row>
      <!-- see https://getbootstrap.com/docs/4.0/utilities/display/#hiding-elements -->
      <b-col class="d-none d-lg-block">
      </b-col>
      <b-col class="col-md-auto">
      <b-input-group>
            <b-button size="sm" @click="goBack"><icon name="arrow-left"/></b-button>
            <b-form-input size="sm" v-model="filter" placeholder="Search" />
            <b-button size="sm" :disabled="!filter" @click="filter = ''">Clear</b-button>
            <b-form-select
                      :options="groups"
                      v-model="selectedGroup">   
            </b-form-select>   
        </b-input-group>
    <b-navbar sticky type="light" variant="light" style="opacity: 0.8;z-index: 5;" class="py-0 px-0 justify-content-center">
    <b-nav-form>
      <b-form-group class="py-0 my-0" label="">
      <b-form-radio-group class="py-0 px-1" size="sm" id="radios1" v-model="selectedPart" :options="options" name="radioOptions">
      </b-form-radio-group>
    </b-form-group>
    </b-nav-form>
  </b-navbar>
  

  <b-table class="py-0 my-0"
            striped hover small
            :items="resultsComputed"
            :fields="fieldsComputed"
            :filter="filter"
            >
            <template v-slot:cell(name)="row">
              <b-link target="_blank" :href="'https://www.mytischtennis.de/community/events?personId=' + row.item.person_id">{{ row.item.name }}</b-link>
            </template>
            <template v-slot:cell(team_short_name)="row">
              <b-link target="_blank" :href="`https://www.mytischtennis.de/community/ranking?panel=1&kontinent=Europa&land=DE&alleSpielberechtigen=yes&verband=Westdeutscher+TTV&bezirk=M%C3%BCnster&kreis=Alle&regionPattern123=DE.WE.R3.19&regionPattern4=17&regionPattern5=02&vereinId=${row.item.club_nr}%2CWTTV&geschlecht=&geburtsJahrVon=&geburtsJahrBis=&ttrVon=&ttrBis=&ttrQuartalorAktuell=aktuell&anzahlErgebnisse=500`">{{ row.item.team_short_name }}</b-link>
            </template>
  </b-table>
      </b-col>
      <b-col class="d-none d-lg-block">
      </b-col>

   </b-row>
  
</b-container>
  
</template>

<script>
import axios from 'axios'
import DBAPI from '../db/dbapi'
import { s2i } from '../helper/Utils'
export default {
  name: 'PlayerRecords',
  mounted() {
    this.processQuery()
    this.getResults()
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processQuery()
      },
      selectedGroup: function (newGroup, oldGroup) {
          // TODO: evt.  _.debounce aus lodash einbauen, siehe https://it.vuejs.org/v2/guide/computed.html#Watchers
          this.getResults()
      }
  },
  data () {
    return {
      results: [],
      teamsById: { },
      filterclub_nr: null,
      filterTeamNr: null,
      selectedPart: 'gesamt',
      options: [
        { text: 'VR', value: 'vr' },
        { text: 'RR', value: 'rr' },
        { text: 'VR+RR', value: 'gesamt'}
      ],
      filter: null,
      selectedGroup: 'alle',
    }
  },
  computed: {
    groups() {
      return [ // TODO: maybe better on DB, is computed to make value etc. dynamic based an DB data
            {
              value:'alle', text:'alle laufenden', shortText:'', active: false,
              group:{season_year:'21-22', groupId:0, groupName:'alle 21-22'},
            },
            // {
            //   value:'334198', text:'3.KK2 18/19', shortText:'3KK Hin', active: false,
            //   group:{season_year:'18-19', groupId:334198, groupName:'3-Kreisklasse-2'},
            // },
            // {
            //   value:'345849', text:'3.KK3 18/19 RR', shortText:'3KK Rüc', active: false,
            //   group:{season_year:'18-19', groupId:345849, groupName:'3-Kreisklasse-3-RR'},
            // },
            // {
            //   value:'334193', text:'KL 18/19', shortText:'KL', active: false,
            //   group:{season_year:'18-19', groupId:334193, groupName:'Kreisliga'},
            // },
            // {
            //   value:'365036', text:'3.KK2 MR 19/20', shortText:'3KK MR', active: true,
            //   group:{season_year:'19-20', groupId:365036, groupName:'3-Kreisklasse-1-MR'},
            // },
            // {
            //   value:'357080', text:'3.KK2 Hin 19/20', shortText:'3KK Hin', active: true,
            //   group:{season_year:'19-20', groupId:357080, groupName:'3-Kreisklasse-2'},
            // },
            // {
            //   value:'356795', text:'KL 19/20', shortText:'KL', active: true,
            //   group:{season_year:'19-20', groupId:356795, groupName:'Kreisliga'},
            // }
            // {
            //   value:'386798', text:'2.KK1', shortText:'2KK', active: true,
            //   group:{season_year:'19-20', groupId:386798, groupName:'2-Kreisklasse-1'},
            // },
            // {
            //   value:'384958', text:'KL 20/21', shortText:'KL', active: true,
            //   group:{season_year:'20-21', groupId:384958, groupName:'Kreisliga'},
            // }
            {
              value:'395538', text:'2.KK1', shortText:'2KK', active: true,
              group:{season_year:'21-22', groupId:395538, groupName:'2-Kreisklasse-1'},
            },
            {
              value:'395652', text:'KL 21/22', shortText:'KL', active: true,
              group:{season_year:'21-22', groupId:395652, groupName:'Kreisliga'},
            }
      ]
    },
    fieldsComputed() {
      var retVal = [
        {key:"name", sortable: true},
        // {key:"team_nr", sortable: false},
        {key:"team_short_name" , label:"Team", sortable: true},
        {key:"tpos", label:"Pos", sortable: true},
        {key:"qttr", label: "QTTR", sortable: true},
        {key:"ttr", label: "TTR", sortable: true}
      ]
      
      retVal = retVal.concat([
                {key:"bil_12", label:"o", sortable: false},
                {key:"bil_34", label:"m", sortable: false},
                {key:"bil_56", label:"u", sortable: false},
                {key:"bil_sum", label:"sum", sortable: false, class:"bilsum"}])

     
      return retVal
    },
    resultsComputed() {
      
      var results = this.results
      var _resultsComputed = [];
      var namesForGroups = null;
      if (this.selectedGroup === 'alle') {
        namesForGroups = this.namesForGroupIds()
      }
      for (var i = 0;i<results.length;i++) {
        var result = results[i]
        if (result.round !== this.selectedPart) {
          continue
        }
        if (this.selectedGroup === 'alle' && !result.appearances) {
          continue
        }
        result.name = 
                (result.fname.length > 2 ? result.fname.substring(0,2) + "." : result.fname) + " " + result.lname
        var teamById = this.teamsById[result.club_nr]
        var teamNrStr = (result.team_nr == 1) ? "" : ("" + result.team_nr)
        
        if (teamById) {
          result.team_full_name = teamById.full_name ? teamById.full_name + " " + teamNrStr : result.club_nr
          result.team_short_name = teamById.short_name ? teamById.short_name + teamNrStr : result.club_nr
        
        } else {
          // team not on DB, take the teamId as name
          result.team_full_name = "clubNr: " + result.club_nr + " " + teamNrStr
          result.team_short_name = "clubNr: " + result.club_nr + " " + teamNrStr
        }
        if (this.selectedGroup === 'alle') {
          result.team_short_name += ' ' + namesForGroups['' + result.group_nr].shortName
        }

        
        result.tpos = "" + result.team_nr + "." + result.pos
        
        result.ttr = result.ttr_data ? result.ttr_data.ttr : ""
        result.qttr = result.ttr_data ? result.ttr_data.qttr : ""

          
        var paarkreuz = 1
        result['bil_12'] = this.makeBilanzForPaarkreuz(paarkreuz, result)
        
        paarkreuz = 2
        result['bil_34'] = this.makeBilanzForPaarkreuz(paarkreuz, result)
        
        paarkreuz = 3
        result['bil_56'] = this.makeBilanzForPaarkreuz(paarkreuz, result)
        
        paarkreuz = 0 // summe der Paarkreuze
        result['bil_sum'] = this.makeBilanzForPaarkreuz(paarkreuz, result)
        
        _resultsComputed.push(result)
      }
      return _resultsComputed;
    }
  },
  methods: {
    getResults: function(){
      var self = this
      this.teamsById = { };
      var filter = {}
      if (this.filterclub_nr) {
        filter.club_nr = this.filterclub_nr
      } 
      if (this.selectedGroup) {
        // filter.group_nr = this.selectedGroup
        var selectedGroups = []
        // filter = {}
        if (this.selectedGroup === 'alle') {
          selectedGroups = this.groups.filter(g => g.active).map(g => g.value)
        } else {
          selectedGroups.push(this.selectedGroup) // only one
        }
        filter.group_nr = {'$in': selectedGroups}
        // filter.round = this.selectedPart;
        filter.$$$json = JSON.stringify(filter)
      }
      DBAPI.getResults(filter).then(function(response) {
          if(response.data.error){
            //app.errorMessage = response.data.message;
            console.error("error calling getResults()")
            console.error(response.data.message)
					}	else {
            // check if 'gesamt' is already filled (which is not the case before season start)
            // if not, we set the selected season part to 'vr' to see the Aufstellung
            var countGesamt = 0;
            response.data.records.forEach(r => {
                if (r.round == 'gesamt') countGesamt++;
              });
            if (countGesamt === 0) {
              self.selectedPart = 'vr';
            }
            
            self.results = response.data.records;
            
            var teamsById = {}
            response.data.teams.forEach(t => {
                teamsById[t.club_nr] = t;
              });
            self.teamsById = teamsById
          }   
      });
      
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        this.filterclub_nr = this.$route.query.club_nr
        
        var groupId = this.$route.query.groupId
        if (groupId) {
          this.groups.forEach(group => {
            if (group.value === groupId) {
              this.selectedGroup = groupId
            }
          }, this);
        }
        var teamNr = this.$route.query.teamNr
        if (teamNr) {
          this.filterTeamNr = teamNr
        }
      }
    },
    makeBilanzForPaarkreuz(_1_2_or_3, result) {
      // _1_2_or_3 = Paarkreuz
      if (result.results.length === 0) return ""

      var retVal = ""
      if (_1_2_or_3 == 0 ) { // sum
        var won_sum = result.results.reduce((acc, curr) => acc + (curr ? curr.w : 0),0)
        var lost_sum = result.results.reduce((acc, curr) => acc + (curr ? curr.l : 0),0)
        
        if (won_sum + lost_sum > 0) {
            retVal = "" + won_sum + ":" + lost_sum
        }

      } else {
        
        var _1_3_or_5 = _1_2_or_3 * 2 - 1 // pos of the better player in the paarkreuz
        var won  = result.results[_1_3_or_5 - 1].w + result.results[_1_3_or_5].w
        var lost = result.results[_1_3_or_5 - 1].l + result.results[_1_3_or_5].l
        
        if (won + lost > 0) {
            retVal = "" + won + ":" + lost
        }        
      }
         
      return retVal
    },
    goBack: function() {
      this.$router.go(-1);
    },
    namesForGroupIds: function(groupId) {
      const groupMap = {}
      this.groups.forEach(g => {
        groupMap['' + g.value] = {shortName:g.shortText, name:g.text}
      })
      return groupMap
    },
  }
}


</script>

<style>
.bilsum {  
    font-weight: bold;
}
</style>
