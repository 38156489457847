var axios = require('axios');
// var MockAdapter = require('axios-mock-adapter');

// see https://github.com/axios/axios#handling-errors
// axios.get('/api', {
//     validateStatus: function (status) {
//       return status < 500; // Reject only if the status code is greater than or equal to 500
//     }
//   })

// function apiget(url, )
axios.defaults.validateStatus = function(status) {return status >= 200 && status < 500;}

var DBAPI = {
    getPlayerSchedules:function(schedParams) { // used by Schedule.vue
        
        if (schedParams) {
            return axios.get('api/schedule', {params:schedParams}) // pollId or playerId in schedParams
        } else {
            console.error("error in DBAPI.getPlayerSchedules: schedParams not set")
            return null
        }
    },
    getResults:function(query) {
        if (!query) {
            return axios.get('api/readresults')
        } else {
            return axios.get('api/readresults', {params:query})
        }
    },
    getPolls:function(filter) {
        return axios.get('api/readpolls', {params:filter})
    },
    getUserData:function(userId) {
        // console.log('process.env.NODE_ENV', process.env.NODE_ENV)
        if (!userId) {
            console.error("error in DBAPI.getMatchData: userId not set")
            return null
        } else {
            return axios.get('api/userwithteam', {params:{user_id:userId}});
            // return axios.get('api/findone', {params:{key:userId,keyName:'user_id', model:"Player"}})
        }
    },
    getFindOne:function(params) {
        if (!params) {
            console.error("error in DBAPI.getFindOne: params not set")
            return null
        } else {
            return axios.get('api/findone', {params:params})
        }
    },
    getFind:function(params) {
        if (!params) {
            console.error("error in DBAPI.getFind: params not set")
            return null
        } else {
            return axios.get('api/find', {params:params})
        }
    },
    putResetTestDbs:function() {
        return axios.put('api/resettestdbs')
    },
    putMongoProd2Local:function() {
        return axios.put('api/monogoprod2local')
    },
    putBackupDb:function() {
        return axios.put('api/backupdb')
    },
    putStartScrape:function() {
        return axios.put('api/startscrape')
    },
    putStartscrapebetinfo:function() {
        return axios.put('api/startscrapebetinfo')
    },
    putPlayerSchedules:function(data) { // used by Schedule.vue
        return axios.put('api/updateschedule', data)
    },


    /*
        query:
            {model:'Poll', keyNames:['_id'], items:[item1, item2]}
            or
            [{model:'Poll', action:'upsert', keyNames:['_id'], items:[item1, item2]}] ; action is upsert or delete; default=upsert
            or
            {options:{opt1, opt2},data:{model:'Poll', keyNames:['_id'], items:[item1, item2]}}
            or
            {options:{opt1, opt2},data:[{model:'Poll', keyNames:['_id'], items:[item1, item2]}]}

    */
    putUpsert:function(query) { // TODO: change name since delete is also possible
        // console.log(query)
        var params = {}
        if (!query) throw new Error('query not set')
        if (!query.options) {
            params = {options:null, data:query}
        } else {
            params = {options:query.options, data:query.data}
        }
        return axios.put('api/upsert', params)
        // return axios.put('api/upsert', {params:query})
    },
    // putDbActions:function(query) {
    //     console.log(query)
    //     return axios.put('api/dbactions', {params:{query, generateId:true}})
    // },
    putDBInsertPoll:function(data) { //used by ImportMatches.vue
        // console.log("newpoll", data)
        return axios.put('api/poll', data//), {
            // headers: {
            //   'Content-Type': 'application/json',
            //   // 'Content-Type': 'application/x-www-form-urlencoded',
              
            // }
        //   }
        )
    }
}

export default DBAPI