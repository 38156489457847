<template>
   <b-container class="mx-0 px-0">
     <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
      <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
      <div v-else-if="appState=='ready'"> 

    
      
      <b-row class="mb-2">
        <b-col>
          <h3>TTR-Berechnung für:</h3>
           <b-form-select size="lg" v-model="playerSelected" :options="playersOptions"></b-form-select>
        </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 align="center">
       alt: {{ playerSelected.ttr }} <b-badge :variant="ttrDeltaBadgeVariant">{{ ttrDeltaComputed }}</b-badge> neu: {{ playerSelected.ttr + ttrDeltaComputed }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Gegner</h3>
         <ol>
         <li v-for="(opp, index) in resultsOpp" :key="opp.person_id" :class="selected[index] == 'gewonnen' ? 'bg-success' : (selected[index] == 'verloren' ? 'bg-danger' : 'bg-secondary')">
           <b-row>
                <b-col v-if="!opp.editable">
                  {{ opp.lname }} {{ opp.ttr_data ? "(" + opp.ttr_data.ttr + ")" : "(-)" }}  
                </b-col>
                <b-col v-else>
                   {{ opp.lname }} <b-form-select v-model="opp.ttr_data.ttr" :options="ttr_options"></b-form-select>
                </b-col>
                
                <b-col>
              
                  <b-form-group>
                      <b-form-radio style="margin:0 4px;" button button-variant="outline-primary" v-model="selected[index]" name="some-radios" value="-"><icon name="user-slash" scale="1.5"></icon></b-form-radio>
                      <b-form-radio style="margin:0 4px;" button button-variant="success" v-model="selected[index]" name="some-radios" value="gewonnen"><icon name="thumbs-up" scale="1.3"></icon></b-form-radio>
                      <b-form-radio style="margin:0 4px;" button button-variant="danger" v-model="selected[index]" name="some-radios" value="verloren"><icon name="thumbs-down" scale="1.3"></icon></b-form-radio>
                  </b-form-group>
                </b-col>
                
            </b-row>
        </li>
      </ol>
      </b-col>
    </b-row>
      </div>
     </b-container>
</template>

<script>

import Utils,{ makeCopy, s2i } from '../helper/Utils'
import DBAPI from '../db/dbapi'
import ModelUtils from '../helper/ModelUtils'
import PlayerState from '../helper/PlayerState'

export default {
  name: 'TtrCalc',
  mounted() {
    this.processParams();
    this.processQuery();
    this.getData();
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processParams()
        this.processQuery()
      },
      'playerSelected': function(newSel, oldSel) {
        this.updateTtr();
      }


      
    },
  data () {
    return {
      matchId:"",
      pollId:null,
      teams: [],
      matches: [],
      playerSchedules: [],
      showOpponentData: false,
      filterPresent: false,
      scheduleForAllPlayers: false,
      resultsOwn: [],
      resultsOpp: [],  // für die Anzeige 
      resultsOwn_db: {vr: [], rr: [], gesamt: []}, // aus DB, aufgeteilt in 'vr', 'rr', 'gesamt'
      resultsOpp_db: {vr: [], rr: [], gesamt: []}, // aus DB, aufgeteilt in 'vr', 'rr', 'gesamt'
      playerSelected: null, // der Spieler, für den der TTR berechnet wird
      playersOptions: [],   // die Spieler zur Auswahl, für die der TTR ausgerechnet werden kann
      poll: { },
      appState:'loading',
      selected:[],
      ttr_selected: 1500,
      ttr_options: arrFromTo(700,2501)
      // loptions: [
      //     { text: '-', value: '-'},
      //     { html: '<b>Item</b> 3', value: 'gewonnen' },
      //     { text: 'verloren', value: 'verloren' }
      //   ]
    }
  },
  computed: {
    myClubNr() {return this.poll.club_nr},
    myTeamNr() {return this.poll.team_nr},
    groupId() {return this.poll.groupId},
    ttrDeltaComputed() {
      return this.computeTtr();
    },
    ttrDeltaBadgeVariant() {
      const ttrDelta = this.computeTtr();
      if (ttrDelta > 0) return 'success';
      if (ttrDelta < 0) return 'danger';
      return 'dark';
    }
  },
  methods: {
    
    headname(name) { return 'head(' + name + ')'},
    cellname(name) { return 'cell(' + name + ')'},
    footname(name) { return 'foot(' + name + ')'},
    computeTtr() {
      if (!this.playerSelected) return 0;

      var won = 0;
      var lost = 0;
      var P_wins = 0;
      var ttrOpps = [];
      var c = 16;
      for (var i = 0; i<this.selected.length;i++) {
        if (this.selected[i] != '-') {
          // console.log(this.selected[i] + " vs. ",  this.resultsOpp[i].ttr_data.ttr)
          ttrOpps.push(this.resultsOpp[i].ttr_data.ttr);
          if (this.selected[i] == 'gewonnen') {
            won = won + 1;
          }
          if (this.selected[i] == 'verloren') {
            lost = lost + 1;
          }
          P_wins += 1. / (1. + Math.pow(10, (this.resultsOpp[i].ttr_data.ttr - this.playerSelected.ttr)/150))
        }

      }
      
      return Math.round((won - P_wins) * c);
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      var userData = this.$$$userData()
      
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }
      var schedParams = {}
      if (this.pollId) {
        schedParams = {pollId: this.pollId}
      } else {
        // eine passende pollId muss zunächst anhand der userId (club_id, team_nr) ermittelt werden
        // das ist für type=u der Fall, siehe processParams
        schedParams = {playerId: userId}
      }
      
      schedParams.scheduleForAllPlayers = 1;//= this.scheduleForAllPlayers ? 1 : 0;

      DBAPI.getPlayerSchedules(schedParams)
      .then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error || response.data.errors){
          console.error("error in getPlayerSchedules", response.data);
          throw new Error("error in getPlayerSchedules");
          // self.appState = 'error'

          //app.errorMessage = response.data.message;
        }	else {
          // console.log(response.data.poll)
          var teams   = {}
          response.data.teams.forEach(t => {teams[t.club_nr] = t})
          self.teams = teams
          
          self.matches = response.data.poll.matches || []
          self.poll = response.data.poll;

          // alle Spieler die Ersatz spielen dürfen:
          self.playerSchedules = response.data.allplayers.filter(p => Number(p.team_nr) >= Number(self.poll.team_nr) && p.is_active === true);
          
          var poll = self.poll
          self.setPollId(poll._id)
          
          self.showOpponentData = false // $lot
          
         
          // return 1;
        }       
    })
   




      .then(function(result) {
        return self.getResultsOwn();
      })
      .then(function(result) {
        return self.getResultsOpp();
      })
      .then(function(result) {
          self.setAppData();
          self.appState = 'ready'
          return true;
      })
      .catch(function(e) {
          console.error("error in getPlayerSchedules", e)
          self.appState = 'error'
      });
    },
    getResultsOpp() {
      var opponentTeamInfo = this.opponentTeamInfo()
      const filter = {club_nr:opponentTeamInfo.clubNr, group_nr:this.groupId};//,  round:this.poll.round};
      return this.resultsForTeamId(filter, 'opp')
    },
    getResultsOwn() {
      const filter = {club_nr:this.myClubNr};//, group_nr:this.groupId};
      return this.resultsForTeamId(filter, 'own')
    },
    setAppData() {
      var self = this;
      
      self.playersOptions = self.playerSchedules.map(p => {
          var resultsOwn = resultById(self.resultsOwn_db['gesamt'], p.person_id);
          if (!resultsOwn) resultsOwn = resultById(self.resultsOwn_db['vr'], p.person_id);
          const ttr = resultsOwn && resultsOwn.ttr_data ? resultsOwn.ttr_data.ttr : "-";
          return {
            value: {
              p_id : p._id,
              person_id:p.person_id,
              pos:p.pos,
              fname:p.fname,
              lname:p.lname,
              name:p.lname,
              ttr: ttr,
            
            },
            text: p.fname + " " + p.lname + " (" + ttr + ")"
            }
          });

      self.selected.splice(0); // empty array
      for (var i = 0;i<self.resultsOpp.length;i++) {
        self.selected.push('-');
      }
      // wähle den Spieler aus, der angemeldet ist:
      var userData = self.$$$userData();

      for (var i=0;i<self.playersOptions.length;i++) {
        if (self.playersOptions[i].value.person_id == userData.person_id) {
          self.playerSelected = self.playersOptions[i].value;
        }
      }
      if (!self.playerSelected && self.playersOptions.length) {
        self.playerSelected = self.playersOptions[0].value;
      }

    },
    processParams() {
      
      if (this.$route.params) {
        this.setPollId(this.$route.params.pollId)
        this.matchId = this.$route.params.matchId
      } else {
        // poll zum Anzeigen nicht gefunden
        this.$router.push('/polls')
      }
    },
    setPollId(pollId) {
      this.pollId = pollId
      this.$$$storeSet("lastPollId", this.pollId)
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        
      }
    },
    // handleEditClicked(opp, target) {
    //   console.log(opp);
    //   opp.ttr_data.ttr = 1400;
    // },
    resultsForTeamId(filter, ownOrOpp) {
      
      const self = this;
      return DBAPI.getResults(filter).then(function(response) {
          if(response.data.error){
            //app.errorMessage = response.data.message;
            console.error("error calling resultsForTeamId("+ clubNr + ")")
            console.error(response.data.message)
            throw new Error(response.data.message)
					}	else {
            self.setResults_db(ownOrOpp, response.data.records);
            if (ownOrOpp == 'opp') {
              self.makeOppTeam(); // Aufstellung für Opp (Aufstellung own ergibt sich aus poll bzw. schedule)
              // Daten beimischen (bspw. in rr die Bilanz aus gesamt)
              self.completeResultOppData(ownOrOpp);
            }
            return true;
          }       
        });
      
      
    },
    setResults_db(ownOrOpp, records) {
      if (ownOrOpp !== 'own' && ownOrOpp !== 'opp') throw new Error("ownOrOpp must be 'own' or 'opp'")
      const results_db = ownOrOpp === 'own' ? this.resultsOwn_db : this.resultsOpp_db;
      
      const rounds = ['vr', 'rr', 'gesamt'];
      const self = this;

      rounds.forEach(function(r) {
          results_db[r] = records.filter(function(e) {
            return e.round===r;
          });

          results_db[r].sort(function(a,b) {
            if (a.team_nr > b.team_nr) return 1
            if (a.pos > b.pos) return 1
            if (a.pos < b.pos) return -1
              return 0;
          });

      });
    },
    makeOppTeam() {
      const self = this;
      const opponentTeamInfo = this.opponentTeamInfo();
      
      // vr muss immer vorhanden sein, rr ist erst ab Mitte/Ende Dezember verfügbar
      // (in gesamt fehlen leider die Spieler, die noch keinen Einsatz hatten)
      if (this.resultsOpp_db[this.poll.round] && this.resultsOpp_db[this.poll.round].length > 0) {
        // passende Daten für die round dieser Umfrage sind vorhanden
        this.resultsOpp_db[this.poll.round].forEach(function(r) {
          if (r.team_nr >= opponentTeamInfo.teamNr) { // nur spielberechtigte, also nicht aus höheren Mannschaften
            self.resultsOpp.push(r);
          }
        });
      } else {
        if (this.resultsOpp_db['vr'].length === 0) console.log("warning", "resultsOpp_db['vr'] must not be empty")
        this.resultsOpp_db['vr'].forEach(function(r) {
          if (r.team_nr >= opponentTeamInfo.teamNr) { // nur spielberechtigte, also nicht aus höheren Mannschaften
            self.resultsOpp.push(r)
          }
        });
      }
      // add 2 editable players ERSTMAL NUR EINEN weil das editmodel für die ttr-auswahl sonst zu komplex wird
      var editablePlayer = {editable:true};
      
      editablePlayer.ttr_data = {};
      editablePlayer.results = {};
      editablePlayer.ttr_data.ttr = 1500;
      editablePlayer.fname = "manuelle TTR-Eingabe:";
      editablePlayer.lname = "manuelle TTR-Eingabe:";
      this.resultsOpp.push(editablePlayer);           // first
      this.resultsOpp.push(makeCopy(editablePlayer)); // second

    },
    completeResultOppData() {
      // resultById(results, player_id)
      
      const self = this;
      const results           = this.resultsOpp;
      const results_db_gesamt = this.resultsOpp_db['gesamt'];
      const results_db_vr     = this.resultsOpp_db['vr'];

      results.forEach(function(r){
        if (r.editable) return; // künstlich angelegter dummy-Spieler für manuelle Eingabe

        var gesamt = resultById(results_db_gesamt, r.person_id);
        if (!gesamt) { // kommt vor, wenn ein Spieler noch nicht gespielt hat. Damit ttr_data gefüllt werden kann
          gesamt = resultById(results_db_vr, r.person_id);
        }
        // console.log('gesamt', r._id);
        r.results  = makeCopy(gesamt.results);
        r.ttr_data = makeCopy(gesamt.ttr_data);
      });

    },
    updateTtr() {
      for (var i = 0; i<this.selected.length;i++) {

        this.selected[i] = '-';
        
      }
      
    },
    findPlayerData(playerId) {
      return ModelUtils.findPlayerData(this.playerSchedules, playerId)
    },
    opponentTeamInfo() {
      var m = this.findMatch()
      if (!m) {
        return null
      }
      var myClubNr = this.myClubNr
      var away_yn = (m.away_club_nr == myClubNr)
      var oppClubNr = away_yn ? m.home_club_nr : m.away_club_nr;
      var oppTeamNr = away_yn ? m.home_team_nr : m.away_team_nr;

      return {clubNr:oppClubNr,teamNr:oppTeamNr}
      
    },
    findMatch() {
      if (!this.matches) {
        return null
      }
      for(var i = 0;i<this.matches.length;i++) {
        var match = this.matches[i]
        if (match._id == this.matchId) {
          return match
        }
      }      
      return null
    },
    handleTtrClicked(opp) {
      console.log(this.selected)
    },
    handleCheckboxUpdated(row, matchId, event) {
      var item = row.item
      var checked = event
      var newState = checked ? 'yes' : 'no'
      if (item._id) { // item._id is undefined for last few rows when more opponents than own players
        this.setStateForPlayer(item._id, matchId, newState)
      }
    },

    goBack: function() {
      this.$router.go(-1);
    },
    canPlay(playerState) {
      if (!playerState) return false
      return PlayerState.canPlay(playerState.player_state)
    },
    cannotPlay(playerState) {
      if (!playerState) return true
      return PlayerState.cannotPlay(playerState.player_state)
    },
    checkBoxStyle(playerState) {
      if (!playerState || playerState.captain_state == 'unset') {
        return {color: "green"};
      }
      let captain_state = playerState.captain_state
      if (captain_state == 'yes') {
        return {color: "red"}
      }
      return {color: "green"}
    },
    
    s(str) {
      if (!str || !str.length) {
        return "-"
      }
      return str
    }
  }
}

function matchKey(matchId) {
  return "" + matchId
}
function resultById(results, personId) {
  if (!results) {
      return null
  }

  var retVal = null
  results.forEach(r => {
      if (r.person_id == personId) {
        retVal = r
      }
  });

  return retVal
}
function arrFromTo(from, to) {
  var retVal = [];
  for(var i = from;i<=to;i++) {
    retVal.push(i);
  }
  return retVal;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.cursor-pointer {
  cursor:default;/* pointer;*/
}

.cursor-pointer:hover {
    cursor: pointer;
}

.double-size
{
  /* Double-sized Checkboxes */
  transform: scale(1.3);
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  /* padding: 10px; */
}
label{  
    /* width:200px;        */

    /* position:relative; */
    /* left: -20px; */
    text-align: center;
    display:inline-block;    
    vertical-align:middle; 
}
.col-width {
   width: 3.5em;
}
.text-center {
    text-align: center;
}


ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol li {
  color: #fff;
  margin-bottom: 2px;
  padding: 5px 0px 0px 5px;
}

</style>
