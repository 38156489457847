import DBAPI from "../db/dbapi";

// TODO: user is DB table players; rename players to user


// see
// https://pouchdb.com/2015/05/18/we-have-a-problem-with-promises.html
// getUserByName('nolan').then(function (user) {
//     if (inMemoryCache[user.id]) {
//       return inMemoryCache[user.id];    // returning a synchronous value!
//     }
//     return getUserAccountById(user.id); // returning a promise!
//   }).then(function (userAccount) {
//     // I got a user account!
//   });

function updateUserData(signInUserId, store) {
   let SIGN_OUT_ID = ""
    var currentUserId = store.get("userId"); // store must be in sync with cookie here (see main.js)
    if (!signInUserId && signInUserId !== SIGN_OUT_ID) { // wenn signInUserId weder gesetzt noch explizit auf "signOut"
        // console.log("override siud", currentUserId)
        signInUserId = currentUserId
    }

    if (signInUserId && signInUserId !== currentUserId) {
        store.reset()
    }

    return getAndSetUserData(signInUserId)

    function getAndSetUserData(userId) {
        // console.log("getAndSetUD", userId)
        if (!userId && userId !== SIGN_OUT_ID) {
            throw "getAndSetUserData: userId not set"
        }
        if (userId === SIGN_OUT_ID) {
            return Promise.resolve(store.signOut()) // same as reset()
        } else {
            return DBAPI.getUserData(userId) // get Data from server
            .then(function(response) {
                if (!response.data || !response.data.user || !response.data.team) {
                    throw "User mit id=" + userId + " nicht gefunden"
                }
                let userData = response.data.user;
                // for simplicity, put team info to userData
                userData.team_full_name = response.data.team.full_name
                userData.team_short_name = response.data.team.short_name
                if (response.data.person) {
                    userData.ttr = response.data.person.ttr;
                    userData.qttr = response.data.person.qttr;
                } else {
                    userData.ttr  = 0;
                    userData.qttr = 0;
                }
                store.setUserData(userData)

                return userData
            })
            // .catch(console.error.bind(console));

        }
    }        
}
export default {
    updateUserData:updateUserData
}