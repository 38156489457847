/*****************************************
 * Usage:
   import MessageAlertMixin from '../mixins/MessageAlertMixin'
 *
 * ... 
   mixins:[MessageAlertMixin],
 * ...
 * 
 * IN TEMPLATE-SECTION:
 <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>
 * 
 * then call:
 * showAlert('this is an alert') // use defaults
 * or
 * showAlert('this is an alert',
                              {
                                variant:'info',
                                dismissible:false,
                                dismissSecs:3,
                                withProgress:true,
                              })
 *
 *****************************************/

let defaults = {
  variant: 'danger',
  dismissible:true, 
  dismissCountDown:0,
  // alertText:null,
  dismissSecs:0, // start value for dismissCountDown
  withProgress:false
} 

export default {
  data() {
    return {
      alert: {
        alertText: null,
        variant: 'danger',
        dismissible:true,
        dismissSecs: 5,
        dismissCountDown: 0,
      }
    }
  },
  computed: {
    alertComputed() {
      let alertVisible = this.alert.dismissCountDown
      
      let alertProgressVisible = this.alert.withProgress 
      return {
        alertVisible:alertVisible,
        alertProgressVisible:alertProgressVisible,
      }
    },
  },
  methods: {
    showAlert (alertText, options) {
      this.alert.alertText = alertText

      if (!options) {
        options = { }
      }

      // merge defaults to options for not set options
      Object.keys(defaults).forEach(function(key) { if (!(key in options)) {options[key] = defaults[key]} });
      Object.assign(this.alert, options)
      if (this.alert.dismissSecs === 0) {
        this.alert.dismissCountDown = 9999999999
      } else {
        this.alert.dismissCountDown = this.alert.dismissSecs
      }
    },
    hideAlert() {
      this.restoreDefaults()
      // alert.dismissCountDown = 0;
      // alert.alertText = null
    },
    restoreDefaults() {
      this.alert.alertText = null
    
      // this.alert = JSON.parse(JSON.stringify(defaults))
      Object.assign(this.alert, defaults)
    
    },
    countDownChanged(dismissCountDown) {
      
      // if (this.alert.dismissSecs == 0) {
      //   return
      // }
      this.alert.dismissCountDown = dismissCountDown
    },
    dismissed() {
      this.hideAlert()
    },
  },
  
}