<template>
  <b-container class="px-0 pt-1">

    <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>

  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <!-- <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div>  -->
  <div v-else-if="appState=='error'"></div> 
  <div v-else-if="appState=='ready'"> 
        <!-- <b-button v-if="false && $$$isTest()" @click.stop="test123()">
                <span>test out</span>
        </b-button>  -->
      
      <!-- responsive b-table leads to bug: checkboxes in table do not update properly -->
      <b-row>
      <b-col class="d-none d-lg-block"></b-col>
      <b-col class="col-sm-auto">
      <h5 class="pt-2">Hi {{ $$$userData().fname }}<small v-if="showTtr"><sup><b-badge variant="primary">{{ttrString()}}</b-badge></sup></small> - <router-link 
                :to="{ name: 'PlayerRecords', query:{ 
                          club_nr:poll.club_nr,
                          //teamNr:data.field.oppTeamNr,
                          groupId:poll.groupId
                        }
                      }"
               >{{$$$userData().team_short_name}} Bilanzen</router-link></h5> <!-- TODO: configurierbar machen dass nicht angezeigt wird (falls als 2. Tabelle) -->
      <h5>{{ poll.title }}</h5>

      <b-table small bordered data-test-id="table-sched"
        :items="playerScheduleRows"
        :fields="fieldsComputed"
        foot-clone
        >
        <template v-slot:head(posInThisTeam)="data">

        </template>
        <template v-slot:head(fname)="data">
                <b-form-checkbox v-show="showAlleCheckbox"
                     @click.native.stop
                     v-model="filterOldMatches"
                     value="alle"
                     unchecked-value="aktuelle"
                     data-test-id="chb-alle">
                alle
              </b-form-checkbox>
              <b-form-checkbox v-show="hasPermissionForDeleteState()"
                     @click.native.stop
                     v-model="enableDelete"
                     >
                löschen
              </b-form-checkbox>
              <b-form-checkbox v-show="hasPermissionForHidePlayer()"
                    @click.native.stop
                     v-model="enableHidePlayers"
                     >
                <p><span class="nobreak"><icon name="eye-slash"></icon>&nbsp;Modus</span></p>
              </b-form-checkbox>
               <b-form-checkbox v-show="showHideInactivePlayersCB()"
                    @click.native.stop
                     v-model="hideInactivePlayers"
                     >
                <p><span class="nobreak"><icon name="eye-slash"></icon>&nbsp;inaktive</span></p>
              </b-form-checkbox>
        </template>
         <template v-slot:head()="data">
           <!-- empty -->
         </template>
         <!-- tricky/hacky: die folgenden cols sind Spiele und werden anhand des defaults gesetzt (suche nach "footname(match.key)").
          Mit der Änderung der syntax mit vue ist mir nichts besseres eingefallen -->
        <template v-for="(match) in matchColumumns" v-slot:[headname(match.key)]="data">
          <div :key="match.key">
              <p class="py-0 my-0" style="line-height: 1.1;">
              <icon v-if="data.field.away_yn" name="road"/> 
              <icon v-else name="home"/> 
              <br>
              {{ data.label }} <br>
               <small :style="data.field.markDate ? 'color:red;font-weight:bold;' : ''">{{ "" + data.field.matchDay + "."  + data.field.matchMonth + "."}} {{ "(" + data.field.matchWeekDay + ")" }}</small><br>
              
              <small>{{ data.field.matchHours + ':' + ((data.field.matchMinutes >= 10) ? data.field.matchMinutes : '0' + data.field.matchMinutes) }}</small>
              </p>
              <router-link v-if="hasPermissionForAdjournMatch('write')"
                    :to="{ name: 'AdjournMatch', query:{ 
                              pollId:poll._id,
                              matchId:data.field.matchId,
                            }
                          }"
                  >verlegen</router-link>
              <br>
              <router-link 
                    :to="{ name: 'PlayerRecords', query:{ 
                              club_nr:data.field.oppClubNr,
                              //teamNr:data.field.oppTeamNr,
                              groupId:poll.groupId
                            }
                          }"
                  >Bilanzen</router-link>
              

        </div>
        </template>
        
        <template v-slot:cell(editBtn)="row">
            
          <!-- je nach Modus und Berechtigung werden unterschiedliche buttons angezeigt: -->
          <b-button v-show="enableHidePlayers" size="sm" @click.stop="handleHideClicked(row, $event.target)" class="mx-0 my-0"  style="max-height: 2em;">
                <span><icon name="eye-slash"/></span>
          </b-button>
          <b-button v-show="testdummy(row) && row.item._id!=editPlayerId && !enableDelete && !enableHidePlayers && hasPermissionForSchedule('write', {playerId:row.item._id})"
                    size="sm"
                    @click.stop="handleEditClicked(row, $event.target)"
                    class="mx-0 my-0"
                    style="max-height: 2em;"
                    :data-test-id="'btn-edit-' + row.index">
                <span><icon name="edit"/></span>
          </b-button>
          <b-button v-show="enableDelete && row.item._id!=editPlayerId && hasPermissionForDeleteState()"
                  size="sm"
                  @click.stop="handleEditDeleteClicked(row, $event.target)"
                  class="mx-0 my-0"
                  style="max-height: 2em;">
                <span><icon name="trash"/></span>
          </b-button>
          <b-button v-show="row.item._id==editPlayerId"
                    size="sm"
                    @click.stop="handleCancelClicked(row, $event.target)"
                    class="mx-0 my-0"
                    style="max-height: 2em;">
                <span><icon name="times"/></span>
          </b-button>
          <b-button v-show="row.item._id==editPlayerId"
                    size="sm"
                    @click.stop="handleSaveClicked(row, $event.target)"
                    class="mx-0 my-0"
                    style="max-height: 1.9em;"
                    :data-test-id="'btn-save-' + row.index">
                <span><icon name="save"/></span>
          </b-button>
            
        </template>
        
        <template v-for="(match, index) in matchColumumns" v-slot:[cellname(match.key)]="row">
              <div :key="match.key">
                  <div class="p-0 m-0" v-if="row.item._id==editPlayerId">
                  
                    <b-form-checkbox v-if="editModeDelete" class="double-size"
                          :checked="getStateForPlayer(row.item._id, match.key).player_state==='unset' && getStateForPlayer(row.item._id, match.key).captain_state==='unset'"
                          @input="handleCheckboxDeleteUpdated(row, match.key ,$event)"><small>del</small></b-form-checkbox>
                    <div v-else >
                      <b-form-checkbox class="double-size"
                          :checked="canPlay(getStateForPlayer(row.item._id, match.key))"
                          @input="handleCheckboxUpdated(row, match.key ,$event)"
                          :data-test-id="'chb-edit-' + index">
                      </b-form-checkbox>
                      
                      
                      <b-form-checkbox v-if="hasPermissionForCaptainState('write', {playerId:row.item._id})" class="double-size"
                          :checked="pickedForTeam(getStateForPlayer(row.item._id, match.key))"
                          @input="handleCheckboxPickedUpdated(row, match.key ,$event)"><small>c</small></b-form-checkbox>
                    </div>
                    
                  </div>
                  <div v-else>
                    <label v-if="pickedForTeam(row.item[match.key])" class="pb-0 mb-0"><icon class="pb-0 mb-0" v-bind:style="checkColor(row.item[match.key])" name="check"/></label>
                    <label v-else-if="canPlay(row.item[match.key])" class="pb-0 mb-0"><icon class="pb-0 mb-0" v-bind:style="checkColor(row.item[match.key])" name="check"/></label>
                  </div>
              </div>
        </template> 
        <!-- <template v-for="(dummy,index) in ['teamWithPos','posInThisTeam', 'fname', 'posInThisTeamWithInitials']" :slot="'FOOT_'+dummy" slot-scope="data">
              <div :key="index">
              </div>
        </template>  -->
        <template v-slot:foot()="row"><span></span>
          <!-- empty footer by default -->
        </template>
        <template v-for="match in matchColumumns" v-slot:[footname(match.key)]="data">
          <router-link 
                :key="match.key" 
                :style="(data.field.presentPlayersCount<6) ? 'color : red;' : ''" 
                :to="{ name: 'MatchPlan', params:{ 
                          pollId: pollId,
                          matchId:data.field.matchId,
                        }
                      }">
                        {{ data.field.captainPickedPlayersCount>0?(data.field.captainPickedPlayersCount + "/"):"" }}{{ data.field.presentPlayersCount }}</router-link>
            <!-- <div v-bind:class="{ 'text-danger' : (data.field.presentPlayersCount<6) }" :key="match.key">
              {{ data.field.presentPlayersCount }}
            </div> -->
      </template>
      </b-table>
      </b-col>
      <b-col class="d-none d-lg-block"></b-col>
      </b-row>

      <b-button v-show="editPlayerId"  @click.stop="handleSaveClicked()">
                <span>Save</span>
          </b-button> 
      <b-button v-show="editPlayerId"  @click.stop="cancelEdit()">
                <span>Cancel Edit</span>
      </b-button>
      <b-form-checkbox v-show="!editModeDelete && editPlayerId && hasPermissionForOmitInfoMails()"
                     @click.native.stop
                     v-model="omitInfoMails"
                     >
                Mail an Team unterdrücken 
              </b-form-checkbox>
  </div>
  </b-container>
</template>

<script>
import Utils, { makeCopy } from '../helper/Utils'
import ModelUtils from '../helper/ModelUtils'
import Permissions from '../helper/Permissions'
import DBAPI from '../db/dbapi'
import PlayerState from '../helper/PlayerState'
import session from '../store/session'
import MessageAlertMixin from '../mixins/MessageAlertMixin'

const fourMinsMillis = 1000 * 60 * 4;

export default {
  name: 'Schedule',
  mixins:[MessageAlertMixin],
  props: {
    forcePollId: String, // if set, pollId is not used from params
    markDates: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return [];
      }
    }
  },
  mounted() {
    // var store = this.store
    var myStore = this.$$$myStoreScope()
    
    this.filterOldMatches = myStore.filterOldMatches || this.filterOldMatches
    
    this.processParams()
    this.processQuery()
    this.getData()
  },
  beforeDestroy() {
    var myStore = this.$$$myStoreScope()
    // myStore.filterOldMatches = this.filterOldMatches
    this.$$$myStoreSet("filterOldMatches", this.filterOldMatches)
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.hideAlert()
        this.processParams()
        this.processQuery()
      },
      enableHidePlayers: function(newVal, oldVal) {
        if (newVal === false) {
          // clear array
          this.hiddenPlayerIds.splice(0, this.hiddenPlayerIds.length)
        }
      },
    },
  data () {
    return {
      filterOldMatches:'aktuelle',
      editPlayerId:null,
      editModel:null, // states of the player being edited
      editModeDelete:false,
      pollId:null,
      teams: { },
      matches: [],
      playerSchedules: [], // players with their schedule for the shown poll
      poll: { },
      omitInfoMails: false, // if true, no mails are sent from updateapi.php
      enableDelete: false,
      enableHidePlayers: false, // TODO: save state in cookie
      hideInactivePlayers: true,
      hiddenPlayerIds:[],
      showInitials: false,
      showTtr: false,
      updateDate: null, 
      lastSaveDate: Date.now() - fourMinsMillis, // to omit mail when user saves multiple times (start value is 4 min ago)
      appState:'loading',
    }
  },
  computed: {
    showAlleCheckbox() {
      if (!this.matches.length) {
        return false
      }
      if (new Date(this.matches[0].date) > new Date()) {
        return false // don´t show if all matches are in the future
      }
      return true
    },
    playerScheduleRows() {
      let ignoreInactivePlayers = this.hideInactivePlayers
      return ModelUtils.playerScheduleRows(this.playerSchedules, this.editPlayerId, this.poll.team_nr, this.hiddenPlayerIds, ignoreInactivePlayers)
    }, 
    editPlayerIdComputed() {
      return this.editPlayerId
    },
    fieldsComputed() {
      if (!this.matches) {// || !this.playerSchedules.matches) {
        return []
      }
      var self = this
      let posInThisTeam = self.showInitials ? "posInThisTeamWithInitials" : "posInThisTeam"
      var retVal = [
        {key: posInThisTeam, label: "Pos", class:"pos-class", thStyle:"border-right: 0px solid;"},
        {key: "fname", label: "Name", formatter: (value, key, item) => { return item.fname
          }, thStyle:"vertical-align: middle;border-left: 0px solid;border-right: 0px solid;"
        },
        {key: "editBtn", label: " ", thStyle:"border-left: 0px solid;"}
      ].concat(
        self.matchColumumns
      )
      
      return retVal
    
    },
    matchColumumns() {
      var self = this
      var myClubNr = this.poll.club_nr
      var myTeamNr = this.poll.team_nr
      var matchesCopy = []
      var now = new Date()
      var yesterday = new Date(now)
      yesterday.setDate(yesterday.getDate() - 1)
      
      for(var i = 0;i<this.matches.length;i++) {
        var match = this.matches[i]
        
        if (this.filterOldMatches == 'alle' || new Date(match.date) >= yesterday || i==this.matches.length-1) {
          if (match.state < 100) {
            matchesCopy.push(match)
          }
        }
      }
      return matchesCopy.map(m => {
            var away_yn = (m.away_club_nr == myClubNr && m.away_team_nr == myTeamNr)
            var oppClubNr = away_yn ? m.home_club_nr : m.away_club_nr;
            var oppTeamNr = away_yn ? m.home_team_nr : m.away_team_nr;
            var matchDate = new Date(m.date)
            
            var matchYear = matchDate.getFullYear() 
            var matchMonth = matchDate.getMonth() + 1
            var matchDay = matchDate.getDate() 
            var matchHours = matchDate.getHours() 
            var matchMinutes = matchDate.getMinutes() 
            let DAYS = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
            var matchWeekDay = DAYS[matchDate.getDay()]
            var playerStatsForMatch = self.playerStatsForMatch(m._id)
            var presentPlayersCount = playerStatsForMatch.present
            var captainPickedPlayersCount = playerStatsForMatch.captain_picked

            var oppShortName = self.teams[oppClubNr] ? self.teams[oppClubNr].short_name : oppClubNr // falls Team nicht in DB
            if (oppTeamNr != 1) {
              oppShortName = oppShortName + oppTeamNr
            }
            return {
                key:"" + m._id,
                matchId:m._id,
                oppClubNr:oppClubNr,
                oppTeamNr:oppTeamNr,
                label:oppShortName,
                away_yn:away_yn,
                matchDate:matchDate,
                matchYear:matchYear,
                matchMonth:matchMonth,
                matchDay:matchDay,
                matchHours:matchHours,
                matchMinutes:matchMinutes,
                matchWeekDay:matchWeekDay,
                presentPlayersCount:presentPlayersCount,
                captainPickedPlayersCount:captainPickedPlayersCount,
                markDate:this.checkMarkDate(matchDate),
                class:"col-width text-center",
                
              }
          })
    }
  },
  methods: {
    headname(name) { return 'head(' + name + ')'},
    cellname(name) { return 'cell(' + name + ')'},
    footname(name) { return 'foot(' + name + ')'},
    testdummy(row) {
      // console.log("testdummy row", row);
      return true;
    },
    test123(){
      console.log("last save date", new Date(this.lastSaveDate))
    },
    ttrString() {
      const userData = this.$$$userData();
      if (userData.ttr === 0 || userData.qttr === 0) return "";
      const ttrString = userData.ttr ? 'ttr=' + userData.ttr : '';
      const qttrString = userData.qttr ? 'ttr=' + userData.qttr : '';
      return `ttr:${userData.ttr} qttr:${userData.qttr}`
    },
    checkMarkDate: function(checkMe) {
      const isoDateString = Utils.toISOLocal(checkMe).substring(0,10)
      // console.log("chmd", isoDateString);
      return this.markDates.includes(isoDateString);
    },
    handleScroll: function (event) {
      // console.log(event)
      
      if (document.documentElement.scrollLeft > 90 || document.body.scrollLeft > 90 /* Safari */) {
        this.showInitials = true
      } else {
        this.showInitials = false
      }
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        // console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }
      var schedParams = {}
      if (this.pollId) {
        schedParams = {pollId: this.pollId};
      } else {
        // eine passende pollId muss zunächst anhand der userId (club_nr, team_nr) ermittelt werden
        // das ist für type=u (landing page) der Fall, siehe processParams
        schedParams = {userId: userId}
      }
      // console.log('userId', userId)
      DBAPI.getPlayerSchedules(schedParams)
      .then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error){
          console.error("error in getPlayerSchedules", response.data.message)

          self.appState = 'error'
          self.showAlert("Konnte Spielerdaten nicht lesen.")
          // TODO: add button to /polls
        }	else {
          if (!response.data.poll || !response.data.poll._id) {
            // no poll found --> show acitve polls
            self.$router.push('/polls')
            return
          }
          var teams   = {}

          response.data.teams.forEach(t => {
              teams[t.club_nr] = t;
            })
          self.teams = teams
          // console.log(self.teams)
          self.matches = response.data.poll.matches || []
          
          self.playerSchedules = response.data.playerSchedules  // player data with schedule for matches
          // console.log('self.playerSchedules', self.playerSchedules)
          self.poll = response.data.poll

          self.setPollId(self.poll._id)
          var matches = self.matches
          
          if (matches.length) {
            var now = new Date()
            var lastMatch = matches[matches.length-1]
            if (new Date(lastMatch.date) <= now) { // wenn alle Spiele in Vergangenheit: zeige alle an
              self.filterOldMatches == 'alle'
            }

          }
          
          // falls user selbst inactive: inactive per default anzeigen
          if (!userData.is_active) {
            self.hideInactivePlayers = false;
          }
          self.updateDate = Date.now();
          self.appState = 'ready';

        }       
    })
    .catch( function(error) {
        console.error("error in getPlayerSchedules (catch)", error)

        self.appState = 'error'
        self.showAlert("Konnte Spielerdaten nicht lesen.")
      });
    },
    processParams() {
      var myStore = this.$$$myStoreScope()
      
      var storeData = this.$$$storeData()
      
      if (this.forcePollId) {
        this.setPollId(this.forcePollId);
        return;
      }

      if (this.$route.params) {
        
        if(this.$route.params.type === "u") { // landing page=>no poll id in params
          if (storeData.lastPollId) {
            // this.setPollId(storeData.lastPollId) do *NOT* use lastPollId for landing page, since in case
            // a new season has started, the user will always land on the old (last) poll
          } else {
            // nothing to do in this case: we search the poll via userId in getData
          }
        } else {
          this.setPollId(this.$route.params.pollId)
        }
      } else {
        // poll zum Anzeigen nicht gefunden, da params nicht gesetzt
        this.$router.push('/polls')
      }
    },
    setPollId(pollId) {
      this.pollId = pollId
      this.$$$storeSet("lastPollId", this.pollId)
    },
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        
      }
    },
    hasPermissionForSchedule(readOrWrite, params /* id of other user to read or write */) {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      
      if (readOrWrite === "write" && this.$$$storeGet("noEdit")) {
        return false
      }

      let forPlayerId = params.playerId
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      
      if (permissionGroup >= 500) { // admin
          return true
      } 
      
      // ---
      // standard permissions
      // ---
      // may see schedules of own club
      if (readOrWrite == "read" &&
          userData.club_nr == forClubNr /*&& userData.team_nr == params.team_nr*/) {
          return true
      } 
      
      // may edit own schedules
      if (readOrWrite == "write" &&
          userData._id == forPlayerId) {
          return true
      } 

      // team admin may edit schedules of own team members
      if (readOrWrite == "write" &&
          userData.club_nr == forClubNr && userData.team_nr == forTeamNr  && permissionGroup >= 200 ) {
          return true
      } 

      return false
    },
    hasPermissionForMatchPlan(readOrWrite, params) {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      if (readOrWrite === "write" && this.$$$storeGet("noEdit")) {
        return false
      }

      let forPlayerId = params.playerId
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      if (permissionGroup >= 500) { // admin
          return true
      } 
      
      // ---
      // standard permissions
      // ---
      // may see schedules of own club
      if (readOrWrite == "read" &&
          userData.club_nr == forClubNr /*&& userData.team_nr == params.team_nr*/) {
          return true
      } 
      

      if (readOrWrite == "write" &&
          userData.club_nr == forClubNr && permissionGroup >= 300 ) {
          return true
      } 
      
      if (readOrWrite == "write" &&
          userData.club_nr == forClubNr && userData.team_nr == forTeamNr  && permissionGroup >= 200 ) {
          return true
      } 

      return false
    },
    hasPermissionForCaptainState(readOrWrite, params) {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      if (this.$$$storeGet("noEdit")) {
        return false
      }

      let forPlayerId = params.playerId
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
            
      // since many teams don`t use the feature captain_state, it must be switched on explicitly
      return Permissions.hasPermission(userData, "1004");
    },
    hasPermissionForAdjournMatch(readOrWrite) {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      if (readOrWrite === "write" && this.$$$storeGet("noEdit")) {
        return false
      }

      let action = "adjourn match"

      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      if (permissionGroup >= 500) { // admin
          return true
      } 
      // club admin may adjourn matches of all teams of club (means: no team_nr here)
      if (readOrWrite == "write" 
          && userData.club_nr == forClubNr && permissionGroup >= 300 ) {
          return true
      } 
      // team admin may adjourn matches of own team
      if (readOrWrite == "write"
          && userData.club_nr == forClubNr && userData.team_nr == forTeamNr  && permissionGroup >= 200 ) {
          return true
      } 

      return false
    },
    hasPermissionForAdmin(readOrWrite) {
      // console.log(params.playerId)
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      
      let action = "admin"
      
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      if (permissionGroup >= 500) { // admin
        return true
      } 

      return false
    },
    hasPermissionForOmitInfoMails() {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      
      
      if (permissionGroup >= 300) { // club admin
          return true
      }

      return Permissions.hasPermission(userData, "1001")
    },
    hasPermissionForDeleteState() {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      
      if (this.$$$storeGet("noEdit")) {
        return false
      }

      
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      if (permissionGroup >= 300) { // club admin
          return true
      }

      return Permissions.hasPermission(userData, "1002")
    },
    hasPermissionForHidePlayer() {
      var userData = this.$$$userData()
      let permissionGroup = this.$$$store.getPermissionGroup()
      
      let forClubNr = this.poll.club_nr
      let forTeamNr = this.poll.team_nr
      
      if (permissionGroup >= 300) { // club admin
          return true
      }

      return Permissions.hasPermission(userData, "1003")
    },
    showHideInactivePlayersCB() {
      // only show checkbox if there is at least 1 inactive player
      var returnValue = false
      this.playerSchedules.forEach(
        p => {
          // console.log(p._id, p.is_active)
          if (p.is_active != 1) {
            returnValue = true
          }
        }
      )
      return returnValue;
    },
    findPlayerData(playerId) {
      return ModelUtils.findPlayerData(this.playerSchedules, playerId)
    },
    playerStatsForMatch(matchId) {
      var playerStats = {present:0, captain_picked:0, total:0}
      if (!this.playerSchedules) {
        return playerStats
      }
      this.playerSchedules.forEach(sched => {
        if (this.hiddenPlayerIds.length) {
            if (this.hiddenPlayerIds.includes(sched._id)) {
                return // next iteration
            }
        }

        if (sched.is_active != 1) {
            return // next iteration
        }
        
        sched.schedule.forEach(m => {
          if(m.match_id == matchId) {
            playerStats.total++
            if (m.player_state == "yes") {
              playerStats.present++
            }
            if (m.captain_state == "yes") {
              playerStats.captain_picked++
            }
          }
        })
      });
      return playerStats
    },

    handleEditClicked(row, event) {
      var item = row.item
    
      // set playerId
      if (this.editPlayerId != item._id) {
        var pd = this.findPlayerData(item._id)
        this.editModel = Utils.makeCopy(pd)
        
        // set defaults for null values --> TODO: check this with new server
        this.editModel.schedule.forEach(elm => {
          if (!elm.player_state || elm.player_state === 'unset') {
            elm.player_state = this.hasPermissionForDeleteState() ? 'unset' : elm.player_state = 'no' // club admin können null stehen lassen
          }
          if (!elm.captain_state) {
            elm.captain_state = 'unset'
          }
        })

        // if a user edits not own schedule, it defaults to no emails sent
        let userData = this.$$$userData()
        let userId = userData.user_id
        
        if (userId !== item.user_id) {
          this.omitInfoMails = true
        }
        this.editPlayerId = item._id
      } else {
        
      }
    },
    handleHideClicked(row, event) {
      var item = row.item
    
      this.hiddenPlayerIds.push(item._id)
    },
    handleEditDeleteClicked(row, event) {
      this.editModeDelete = true
      var item = row.item
    
      // set playerId
      if (this.editPlayerId != item._id) {
        var pd = this.findPlayerData(item._id)
        this.editModel = Utils.makeCopy(pd)
       
        // set defaults for null values --> not needed with new server
        // this.editModel.schedule.forEach(elm => {
        //   if (elm.player_state === null) {
        //     elm.player_state = '0'
        //   }
        //   if (elm.captain_state === null) {
        //     elm.captain_state = '0'
        //   }
        // })

        this.editPlayerId = item._id
      }
      
    },
    handleCancelClicked(row, event) {
      this.cancelEdit()
    },
    handleSaveClicked(row, event) {
      var editModelCopy = Utils.makeCopy(this.editModel)
      // vor cancelEdit müssen alle Parameter für commitEdit gemerkt werden,
      // weil cancelEdit() einiges zurück setzt
      editModelCopy.editModeDelete = this.editModeDelete
      editModelCopy.omitInfoMails  = this.omitInfoMails
      this.cancelEdit()
      this.commitEdit(editModelCopy)
    },
    handleCheckboxUpdated(row, matchId, event) {
      var item = row.item
      var checked = event
      var newState = checked ? 'yes' : 'no'
      this.setStateForPlayer(item._id, matchId, newState)
    },
    handleCheckboxPickedUpdated(row, matchId, event) {
      var item = row.item
      var checked = event
      var newState = checked ? 'yes' : 'unset'
      this.setCaptainStateForPlayer(item._id, matchId, newState)
    },
    handleCheckboxDeleteUpdated(row, matchId, event) {
      var item = row.item
      var checked = event
      var newState = checked ? 'unset' : 'no'
      this.setStateForPlayer(item._id, matchId, newState)
      if (newState === 'unset') {
        this.setCaptainStateForPlayer(item._id, matchId, newState)
      }
    },
    // returns the updated game ids with the new and old states
    commitEdit(editModel) {
      if (!editModel) {
        throw new Error("editModel not set")
      }
      const playerSchedule = this.findPlayerData(editModel._id)
     
      // TODO: spinner anzeigen und state für die GUI erst nach erfolgreichem axios-Aufruf updaten
      this.appState = 'loading' // small problem: this way app looses scroll position, idea: spinner for the row of the player only
      // copy states from editModel
      

      if (editModel.editModeDelete) {
        editModel.omitInfoMails = true
      }
      
      // only send mails if last mail >= 4 min ago
      if (Date.now() - this.lastSaveDate  < fourMinsMillis) {
        editModel.omitInfoMails = true
      } 
  
      // https://github.com/axios/axios/issues/827
      var self = this
      const body = {};
      body.newSchedule = editModel;
      body.oldSchedule = playerSchedule;
      DBAPI.putPlayerSchedules(body).then(function(response) {
          
          if (response.data.error){
            //app.errorMessage = response.data.message;
            self.appState = 'error'
            console.error(response.data.error)
            self.showAlert("Update fehlgeschlagen.\nBitte Seite neu laden oder via \"Übersicht\" neu einsteigen.", {variant:'danger', dismissible:false})
					}	else {
            // console.log(response.data)
            // self.playerSchedules[0].schedule = response.data.schedule
            playerSchedule.schedule = response.data.schedule

            if (response.data.updated) {
              if (!editModel.omitInfoMails) { // without this you cannot send mail for 4mins if you change sth with omitInfoMails
                self.lastSaveDate = Date.now(); // prevent mails for 4 mins
              }
            } else {
              self.lastSaveDate = Date.now() - fourMinsMillis; // reset since nothing changed
              self.showAlert("Es wurde nichts geändert.", {variant:'info', dismissSecs:3, withProgress:true});
            }
            self.appState = 'ready'
          }
      }).catch(e => {
          self.appState = 'error'
          console.error("axios catch: ", e, e.response.data.error);
          // console.error("axios catch: ", e);
          // TODO: siehe https://alligator.io/vuejs/dynamic-components/ um Link auf diesen Poll im v-alert anzuzeigen, evt. im footer?
          self.showAlert("Update fehlgeschlagen.\nBitte Seite neu laden oder via \"Übersicht\" neu einsteigen.", {variant:'danger', dismissible:false})
        });
    },
    // only updates the local editModel
    setStateForPlayer(playerId, matchId, state) {
      if (!this.editModel) {
        throw new Error("editModel not set")
      }
      if (playerId != this.editModel._id) {
        throw new Error("editModel._id="+this.editModel._id+", expected="+playerId)
      }
      var schedule = this.editModel.schedule  // only change editModel
      schedule.forEach(s => {
        if (s.match_id == matchId) {
          s.player_state = state
        }
      });
    },
    // only updates the local editModel
    setCaptainStateForPlayer(playerId, matchId, state) {
      
      if (!this.editModel) {
        throw new Error("editModel not set")
      }
      if (playerId != this.editModel._id) {
        throw new Error("editModel._id="+this.editModel._id+", expected="+playerId)
      }
      var schedule = this.editModel.schedule  // only change editModel
      schedule.forEach(s => {
        if (s.match_id == matchId) {
          s.captain_state = state
        }
      });
    }, 
    // based on editModel
    getStateForPlayer(playerId, matchId) { // TODO: check if easyer now with state for every match is save
      if (!this.editModel) {
        throw new Error("getStateForPlayer: editModel not set")
      }
      if (playerId != this.editModel._id) {
        throw new Error("getStateForPlayer: editModel._id="+this.editModel._id+", expected="+playerId)
      }
      var schedule = this.editModel.schedule 
      var retVal = null
      schedule.forEach(s => {
        if (s.match_id == matchId) {
          retVal = {player_state:s.player_state, captain_state:s.captain_state}
        }
      });

      return retVal
    }, 
    cancelEdit() {
      this.editPlayerId = null
      this.editModel = null
      this.editModeDelete = false
      // this.omitInfoMails = false
    },
    canPlay(playerState) {
      if (!playerState) return false
      return PlayerState.canPlay(playerState.player_state)
    },
    pickedForTeam(playerState) {
      if (!playerState) return false
      return playerState.captain_state == 'yes'
    },
    checkColor(playerState) {
      if (!playerState || playerState.captain_state == 'no') {
        return {color: "green"};
      }
      let captain_state = playerState.captain_state
      if (captain_state == 'yes') {
        return {color: "red"}
      }
      return {color: "green"}
    }
  },
}
// compares two schedules --> true is relevant difference, false else 
function checkUpdateNeeded({oldSchedule, newSchedule}) {
  console.log("old", oldSchedule.schedule.length, oldSchedule);
  console.log("new", newSchedule.schedule.length, newSchedule);

  if (oldSchedule.length !=  newSchedule.length) return true;

  for (var i = 0;i < oldSchedule.schedule.length;i++) {

  }
  return true; // FIXME
  return false;
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>

.tbl-cursor {
  background:#42b983;
    cursor:default;/* pointer;*/
}

.tbl-cursor:hover {
    cursor: pointer;
}

.double-size {
  /* Double-sized Checkboxes */
  transform: scale(1.3);
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  /* padding: 10px; */
}
label {  
    /* width:200px;        */

    /* position:relative; */
    /* left: -20px; */
    text-align: center;
    display:inline-block;    
    vertical-align:middle; 
}
.col-width {
    width: 3.5em;
}
.text-center {
    text-align: center;
}

.pos-class { /* to fix the first column on horizontal scrolling */
    max-width: 2.2em;
    position: sticky;
    overflow-x: visible;
    text-overflow: visible;
    left: 0px;
    background-color: #ffffff;
}
/* .iconAlign { */
    /* position: absolute; */
    /* top: 10%; */
    /* left: 10%; */
    /* height: 10%; */
    /* transform: translate(-50%, -50%); */
    /* width: 10px;
    height: 10px; */
    /* display: block; */
    /* background: red; */
/* } */
.icon-align {
  position: relative;
  /* Adjust these values accordingly */
  top: 3px;
  left: 1px;
}
/* table tr {

white-space:nowrap;

} */
span.nobreak {
  white-space: nowrap;
}
</style>
